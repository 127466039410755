import { resetGroupCartSlice } from "../../redux/stores/reducers/groupCartSlice";
import { setPaymentStatus } from "../../redux/stores/reducers/paymentSlice";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";

async function displayRazorpay({
  eventId,
  ticketId,
  amount,
  dispatch,
  transactionId,
  userData,
  couponId,
  discount,
  billing,
  currency,
}) {
  dispatch(setPaymentStatus("ongoing"));
  const orderId = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/payment/razorpay`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        eventId: eventId,
        ticketId: ticketId,
        receiptId: transactionId,
        amount: amount,
        couponId: couponId,
        currency: currency,
      }),
    }
  );

  const dataR = await orderId.json();
  if (!dataR.success) {
    dispatch(
      setShowToast({
        message: "Internal Server Error! Please Retry",
        type: "danger",
      })
    );
    dispatch(setPaymentStatus("failed"));

    return;
  }
  localStorage.removeItem("tempUserPayDetails");

  var options = {
    key: process.env.REACT_APP_RAZORPAY_KEY_ID,
    amount: dataR.data.amount.toString(),
    currency: currency,
    name: "IAPEN ICNC",
    description: "",
    config: {
      display: {
        hide: [{ method: "paylater" }, { method: "upi" }],
        preferences: { show_default_blocks: true },
      },
    },
    image:
      "https://ik.imagekit.io/k3m4pqzpmlr/coupons/1880-moon-outline_TBPed9a84.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1640773110779",
    order_id: dataR.data.id,
    handler: async function (response) {
      dispatch(setPaymentStatus("ongoing"));
      let body = {
        ...response,
        amount: dataR.data.amount,
        ticketId: ticketId,
        eventId: eventId,
        couponId: couponId,
        discount: discount,
        currency: currency,
        merchantTransactionId: transactionId,
      };

      if (Array.isArray(userData)) {
        body.users = userData;
        body.billing = billing ? billing : userData[0];
      } else {
        body = { ...body, ...userData };
      }
      const data = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/payment/razorpay/success`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...body,
          }),
        }
      );
      const data1 = await data.json();
      localStorage.setItem("receiptUrl", data1.url);
      if (data1.payment.razorpay.status === "SUCCESS") {
        localStorage.removeItem("form1");
        localStorage.removeItem("form2");
        localStorage.removeItem("form3");
        localStorage.removeItem("form4");
        localStorage.removeItem("form5");
        localStorage.removeItem("groupForm1");
        localStorage.removeItem("groupForm2");
        localStorage.removeItem("groupForm3");
        localStorage.removeItem("groupForm4");
        dispatch(resetGroupCartSlice());
        dispatch(setPaymentStatus("success"));
        // navigate("/payments/razorpay/success");
      } else {
        dispatch(setPaymentStatus("failed"));
        // dispatch(setPaymentStatus("ongoing"));
      }
    },
    prefill: {
      name: Array.isArray(userData) ? userData[0].name : userData.name,
      email: Array.isArray(userData) ? userData[0].email : userData.email,
      contact: Array.isArray(userData) ? userData[0].mobile : userData.mobile,
    },
    modal: {
      ondismiss: function () {
        dispatch(setPaymentStatus("failed"));
      },
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
  paymentObject.on("payment.failed", async function (response) {
    let body = {
      ...response,
      amount: dataR.data.amount,
      ticketId: ticketId,
      eventId: eventId,
      couponId: couponId,
      discount: discount,
      merchantTransactionId: transactionId,
    };

    if (Array.isArray(userData)) {
      body.users = userData;
      body = { ...body, ...userData[0] };
    } else {
      body = { ...body, ...userData };
    }
    const data = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/payment/razorpay/failure`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...body,
        }),
      }
    );
    const data1 = await data.json();
    paymentObject.close();
    dispatch(setPaymentStatus("failed"));
    // navigate("/payments/razorpay/failure");
  });
}

export default displayRazorpay;
