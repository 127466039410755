import { createSlice } from "@reduxjs/toolkit";

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentDetails: {},
    paymentStatus: "",
  },
  reducers: {
    setPaymentData: (state, action) => {
      return {
        ...state,
        paymentDetails: { ...action.payload },
      };
    },

    setPaymentStatus: (state, action) => {
      return {
        ...state,
        paymentStatus: action.payload,
      };
    },
  },
});

export const { setPaymentData, setPaymentStatus } = paymentSlice.actions;
export default paymentSlice.reducer;
