import React, { useState, useEffect } from "react";

const CountryCode = ({
  countryCode,
  setCountryCode,
  allCountryCode,
  setAllCountryCode,
}) => {
  const [triggerCode, setTriggerCode] = useState(false);

  useEffect(() => {
    if (countryCode) {
      const matchingCountries = allCountryCode.filter(
        (country) =>
          country.dial_code
            .toLowerCase()
            .startsWith(countryCode.toLowerCase()) ||
          country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
          country.code.toLowerCase().startsWith(countryCode.toLowerCase())
      );

      const nonMatchingCountries = allCountryCode.filter(
        (country) =>
          !(
            country.dial_code
              .toLowerCase()
              .startsWith(countryCode.toLowerCase()) ||
            country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
            country.code.toLowerCase().startsWith(countryCode.toLowerCase())
          )
      );

      setAllCountryCode([...matchingCountries, ...nonMatchingCountries]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  return (
    <div className="w-[20%] relative">
      <input
        type="text"
        id="code"
        required
        value={countryCode}
        onFocus={() => setTriggerCode(true)}
        onBlur={() => setTriggerCode(false)}
        onChange={(event) => setCountryCode(event.target.value)}
        className="form-input w-full"
        autoComplete="off"
      />

      {triggerCode && (
        <div className="border max-h-[145px] z-20  absolute bg-white overflow-y-scroll rounded-lg w-full">
          {allCountryCode?.map((codes) => (
            <span
              className="w-[100%] flex h-[40px] items-center pl-2 text-[12px] font-[500] cursor-pointer hover:bg-gray-200 "
              onMouseDown={(event) => {
                setCountryCode(codes.dial_code);
                event.stopPropagation();
              }}
            >
              {codes.code} {codes.dial_code}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountryCode;
