import React from "react";
import { totalSteps } from "../../partials/Steps";

const FormSteps = ({ step, setStep }) => {
  return (
    <div className="flex justify-center">
      {totalSteps?.map((data, index) => {
        return (
          <div className=" mt-[90px]  w-[150px]" key={index}>
            <div className="flex justify-center items-center relative ">
              <span
                className={` absolute top-[-25px] ${
                  data?.num === step
                    ? "text-[16px] font-[600]"
                    : "text-[15px] font-normal"
                } mr-0`}
              >
                {data?.name}
              </span>

              {data?.num <= step ? (
                <div
                  className={`${
                    data?.num === step ? "bg-primary" : "bg-primary"
                  }  cursor-pointer rounded-full  flex justify-center items-center w-[40px] h-[40px] transition-colors duration-300`}
                  onClick={() => setStep(data?.num)}
                >
                  <div className="">{data?.img}</div>
                </div>
              ) : (
                <div
                  className={`bg-white border-2 rounded-full w-[40px] h-[40px] flex justify-center items-center transition-colors duration-300`}
                >
                  <div className="">{data?.img}</div>
                </div>
              )}

              <div className="">
                {totalSteps?.length === data?.num ? (
                  <></>
                ) : (
                  <>
                    {data?.num <= step ? (
                      <div className="absolute border border-primary h-0 w-full transition-all duration-300"></div>
                    ) : (
                      <div className="absolute border h-0 w-full transition-all duration-300"></div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FormSteps;
