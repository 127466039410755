import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addUserData } from "../../redux/stores/reducers/userSlice";
import CountryDropdown from "../../common/CountryDropdown";
import StateDropdown from "../../common/StateDropdown";
import CityDropdown from "../../common/CityDropdown";

const Form3 = ({ setStep }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const [countryResult, setCountryResult] = useState([]);
  const [cityResult, setCityResult] = useState([]);
  const [stateResult, setStateResult] = useState([]);

  const onSubmit = (data) => {
    let obj = {
      address: data?.address?.trim()?.replace(/\s*,\s*/g, " - "),
      country: countryResult?.name?.trim(),
      state: stateResult?.name?.trim(),
      city: cityResult?.name?.trim(),
    };
    dispatch(addUserData(obj));
    localStorage.setItem(
      "form3",
      JSON.stringify({ ...data, countryResult, stateResult, cityResult })
    );
    setStep(4);
  };
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("form3"));
    if (formData) {
      reset({ address: formData?.address });
      setCountryResult(formData?.countryResult);
      setStateResult(formData?.stateResult);
      setCityResult(formData?.cityResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="mymd:w-[500px] mymd:flex w-[100%] justify-center">
          <div className="w-full flex flex-col justify-center">
            <div className="mymd:w-[500px] mb-4">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="address"
              >
                Postal Address for Correspondence (with pin code){" "}
                <span className="text-rose-500">*</span>
              </label>

              <textarea
                {...register("address", {
                  required: "Address is required",
                  validate: (value) => {
                    const trimmedValue = value.trim();
                    if (trimmedValue === "") {
                      return "Please enter a valid address";
                    }
                    return true;
                  },
                })}
                id="address"
                rows={3}
                required
                cols={50}
                className="whitespace-pre-line-wrap peer bg-[#ebeced] text-gray-900 text-[15px] rounded-[10px] w-full py-2 px-3"
                placeholder="Your Address"
              ></textarea>
              {errors?.address && (
                <p className="text-red-500 text-[13px] font-medium mt-1">
                  {errors?.address?.message}
                </p>
              )}
            </div>

            {/* <div className="mymd:w-[500px] mb-4">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="country"
              >
                Country <span className="text-rose-500">*</span>
              </label>
              <input
                id="country"
                className="form-input w-full"
                type="text"
                required
                {...register("country")}
                placeholder="Country"
              />
            </div>

            <div className="mymd:w-[500px] mb-4">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="city"
              >
                City <span className="text-rose-500">*</span>
              </label>
              <input
                id="city"
                className="form-input w-full"
                type="text"
                required
                placeholder="City"
                {...register("city")}
              />
            </div>

            <div className="mymd:w-[500px] mb-4">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="state"
              >
                State <span className="text-rose-500">*</span>
              </label>
              <input
                id="state"
                className="form-input w-full"
                type="text"
                required
                placeholder="State"
                {...register("state")}
              />
            </div> */}

            <CountryDropdown
              countryResult={countryResult}
              setCountryResult={setCountryResult}
            />

            <StateDropdown
              countryResult={countryResult}
              stateResult={stateResult}
              setStateResult={setStateResult}
            />

            <CityDropdown
              setCityResult={setCityResult}
              cityResult={cityResult}
              countryResult={countryResult}
              stateResult={stateResult}
            />

            {/* <label className="flex items-center mt-2 w-[100%]">
              <input
                type="checkbox"
                className="form-checkbox"
                value="Yes"
                id="isBillingAddressSame"
                {...register("isBillingAddressSame")}
              />
              <div className="text-sm font-medium ml-2 cursor-pointer">
                <span className="font-[600]">
                  Billing Address Same as Above{" "}
                </span>
              </div>
            </label> */}

            {/* {watch().isBillingAddressSame !== "Yes" && (
              <>
                <div className="text-black text-[15px] font-[600] my-3">
                  Billing Address
                </div>

                <div className="mymd:w-[500px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-1 text-gray-500"
                    htmlFor="billingAddress"
                  >
                    Address for correspondence
                    <span className="text-rose-500">*</span>
                  </label>
                  <textarea
                    {...register("billingAddress")}
                    id="billingAddress"
                    rows={3}
                    required
                    cols={50}
                    className="whitespace-pre-line-wrap peer bg-[#ebeced] text-gray-900 text-[15px] rounded-[10px] w-full py-2 px-3"
                    placeholder="Your Address"
                  ></textarea>
                </div>

                <div className="mymd:w-[500px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-1 text-gray-500"
                    htmlFor="billingAddressCountry"
                  >
                    Country <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="billingAddressCountry"
                    className="form-input w-full"
                    type="text"
                    required
                    {...register("billingAddressCountry")}
                    placeholder="Country"
                  />
                </div>

                <div className="mymd:w-[500px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-1 text-gray-500"
                    htmlFor="billingAddressCity"
                  >
                    City <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="billingAddressCity"
                    className="form-input w-full"
                    type="text"
                    required
                    placeholder="City"
                    {...register("billingAddressCity")}
                  />
                </div>

                <div className="mymd:w-[500px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-1 text-gray-500"
                    htmlFor="billingAddressState"
                  >
                    State <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="billingAddressState"
                    className="form-input w-full"
                    type="text"
                    required
                    placeholder="State"
                    {...register("billingAddressState")}
                  />
                </div>
              </>
            )} */}
          </div>
        </div>
        <div className="font-[500]">
          Note:
          <p>- GST included for all prices</p>
          <p className="my-1">
            - Pre-conference topics and timings are subjected to change.
          </p>
          <p>
            - For any assistance please contact:{" "}
            <span className="text-primary">+91 85910 76985</span>
          </p>
        </div>
        <div className="flex gap-3 mt-3">
          <button
            type="button"
            onClick={() => {
              setStep(2);
            }}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            className="w-[110px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form3;
