import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroupData,
  addUserData,
  deleteAttendee,
  resetCartTotal,
  resetFormData,
  setIsEdit,
  setRegisterLeader,
} from "../../redux/stores/reducers/userSlice";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";
import { resetCartSlice } from "../../redux/stores/reducers/cartSlice";
import { deleteSingleUserCartData } from "../../redux/stores/reducers/groupCartSlice";

const Form4 = ({ setTriggerPopup, triggerPopup, setStep, step }) => {
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const [clickedButton, setClickedButton] = useState("");
  const groupData = useSelector((state) => state.userSlice.groupFormData);
  const onSubmit = async (data) => {
    let updatedData = { ...data, groupName: data?.groupName?.trim() };

    // if (data?.isBillingAddressSame === "Yes") {
    //   updatedData = {
    //     groupName: data?.groupName,
    //     isBillingAddressSame: "Yes",
    //     billingAddress: `${groupData?.address}, ${groupData?.country}, ${groupData?.state}, ${groupData?.city}`,
    //   };
    // } else if (data?.isBillingAddressSame === false) {
    //   updatedData = {
    //     groupName: data?.groupName,
    //     isBillingAddressSame: "No",
    //     billingAddress: `${data?.billingAddress}, ${data?.billingAddressCountry}, ${data?.billingAddressState}, ${data?.billingAddressCity}`,
    //   };
    // }

    if (clickedButton === "notSubmit") {
      if (groupData?.groupAttendees?.length === 10) {
        dispatch(
          setShowToast({
            message: "Group limit exceeded",
            category: "danger",
          })
        );
        return;
      } else {
        dispatch(addGroupData(updatedData));
        localStorage.setItem("groupForm4", JSON.stringify({ ...data }));
        setStep(5);
      }
    } else if (clickedButton === "registerLeader") {
      if (groupData?.groupAttendees?.length === 10) {
        dispatch(
          setShowToast({
            message: "Group limit exceeded",
            category: "danger",
          })
        );
        return;
      } else {
        dispatch(addGroupData(updatedData));
        localStorage.setItem("groupForm4", JSON.stringify({ ...data }));
        dispatch(setRegisterLeader({ value: true }));
        setStep(5);
      }
    } else {
      if (groupData?.groupAttendees?.length < 2) {
        dispatch(
          setShowToast({
            message: "Please add atleast 2 group attendees",
            category: "danger",
          })
        );
        return;
      }
      dispatch(addGroupData(updatedData));
      localStorage.setItem("groupForm4", JSON.stringify({ ...data }));
      setStep(9);
    }
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("groupForm4"));
    if (formData) {
      reset({
        ...formData,
      });
    }
    dispatch(resetCartTotal());
    dispatch(resetCartSlice());
    dispatch(setIsEdit({ value: false }));
    dispatch(setRegisterLeader({ value: false }));
    dispatch(resetFormData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (attendee) => {
    dispatch(deleteAttendee(attendee?.email));
    dispatch(deleteSingleUserCartData(attendee?.email));
    dispatch(
      setShowToast({
        message: "Attendee deleted",
        category: "success",
      })
    );
  };

  const handleEdit = (attendee) => {
    dispatch(addUserData(attendee));
    dispatch(setIsEdit({ value: true }));
    setStep(5);
  };

  return (
    <>
      <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="mymd:w-[500px] mymd:flex w-[100%] justify-center">
            <div className="w-full flex flex-col justify-center">
              <div className="text-black text-[20px] font-[600] mb-3">
                Group Info
              </div>

              <div className="text-black text-[15px] font-[600] mb-3">
                Group Leader Full Name
                <p className="font-[500]">
                  {groupData?.firstName + " " + groupData?.lastName}
                </p>
              </div>

              <div className="mymd:w-[500px] mb-0">
                <label
                  className="block text-[15px] font-[400] mb-1 text-gray-500"
                  htmlFor="groupName"
                >
                  Group Name <span className="text-rose-500">*</span>
                </label>
                <input
                  id="groupName"
                  className="form-input w-full"
                  type="text"
                  required
                  {...register("groupName", {
                    required: "This field is required",
                    validate: (value) => {
                      const trimmedValue = value.trim();
                      if (trimmedValue === "") {
                        return "Please enter a valid text";
                      }
                      return true;
                    },
                  })}
                  placeholder="Group Name"
                />
              </div>

              {/* <label className="flex items-center mt-2 w-[100%]">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  value="Yes"
                  id="isBillingAddressSame"
                  {...register("isBillingAddressSame")}
                />
                <div className="text-sm font-medium ml-2 cursor-pointer">
                  <span className="font-[600]">
                    Billing Address same as Leader's Address{" "}
                  </span>
                </div>
              </label>

              {watch().isBillingAddressSame !== "Yes" && (
                <>
                  <div className="text-black text-[15px] font-[600] my-3">
                    Billing Address
                  </div>

                  <div className="mymd:w-[500px] mb-4">
                    <label
                      className="block text-[15px] font-[400] mb-1 text-gray-500"
                      htmlFor="billingAddress"
                    >
                      Address for correspondence
                      <span className="text-rose-500">*</span>
                    </label>
                    <textarea
                      {...register("billingAddress")}
                      id="billingAddress"
                      rows={3}
                      required
                      cols={50}
                      className="whitespace-pre-line-wrap peer bg-[#ebeced] text-gray-900 text-[15px] rounded-[10px] w-full py-2 px-3"
                      placeholder="Your Address"
                    ></textarea>
                  </div>

                  <div className="mymd:w-[500px] mb-4">
                    <label
                      className="block text-[15px] font-[400] mb-1 text-gray-500"
                      htmlFor="billingAddressCountry"
                    >
                      Country <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="billingAddressCountry"
                      className="form-input w-full"
                      type="text"
                      required
                      {...register("billingAddressCountry")}
                      placeholder="Country"
                    />
                  </div>

                  <div className="mymd:w-[500px] mb-4">
                    <label
                      className="block text-[15px] font-[400] mb-1 text-gray-500"
                      htmlFor="billingAddressCity"
                    >
                      City <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="billingAddressCity"
                      className="form-input w-full"
                      type="text"
                      required
                      placeholder="City"
                      {...register("billingAddressCity")}
                    />
                  </div>

                  <div className="mymd:w-[500px] mb-4">
                    <label
                      className="block text-[15px] font-[400] mb-1 text-gray-500"
                      htmlFor="billingAddressState"
                    >
                      State <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="billingAddressState"
                      className="form-input w-full"
                      type="text"
                      required
                      placeholder="State"
                      {...register("billingAddressState")}
                    />
                  </div>
                </>
              )} */}

              <button
                type="submit"
                className="mt-3 w-[230px] flex h-[35px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[15px]"
                onClick={() => setClickedButton("registerLeader")}
              >
                Register leader as attendee
              </button>

              <div className="text-black text-[20px] font-[600] my-2">
                Group Members List
              </div>
              {groupData?.groupAttendees?.length < 2 && (
                <div className="flex flex-col gap-2 mb-1">
                  <div className="flex rounded-xl bg-red-100 p-2">
                    <div className="border border-primary rounded-lg p-2 shadow h-[40px] w-[40px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="25"
                        viewBox="0 -960 960 960"
                        width="25"
                        fill="black"
                      >
                        <path d="M730-400v-130H600v-60h130v-130h60v130h130v60H790v130h-60Zm-370-81q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM40-160v-94q0-35 17.5-63.5T108-360q75-33 133.338-46.5 58.339-13.5 118.5-13.5Q420-420 478-406.5 536-393 611-360q33 15 51 43t18 63v94H40Zm60-60h520v-34q0-16-9-30.5T587-306q-71-33-120-43.5T360-360q-58 0-107.5 10.5T132-306q-15 7-23.5 21.5T100-254v34Zm260-321q39 0 64.5-25.5T450-631q0-39-25.5-64.5T360-721q-39 0-64.5 25.5T270-631q0 39 25.5 64.5T360-541Zm0-90Zm0 411Z" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="font-[500] mymd:text-[15px] text-[13px]">
                        Minimum group capacity requirement
                      </p>
                      <p className="font-[400] mymd:text-[13px] text-sm">
                        Add atleast 2 attendee's to continue
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {groupData?.groupAttendees?.length > 0 && (
            <div className="mymd:min-w-[500px] mymd:max-w-[700px] mt-1 rounded-[10px] text-[14px] overflow-hidden border border-gray-300">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-sm font-semibold uppercase text-slate-500 border-b-1 border-slate-200">
                  <tr>
                    <th className="px-2 first:pl-2 last:pr-5 py-[5px] whitespace-nowrap border">
                      <div className="font-semibold text-left">Name</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border hidden mymd:block">
                      <div className="font-semibold text-center">Email</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-[5px] whitespace-nowrap border">
                      <div className="font-semibold text-left">Options</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="md:w-[100%]">
                  {groupData?.groupAttendees?.map((attendee) => {
                    return (
                      <tr className="font-[500]" key={attendee.email}>
                        <td className="px-2 first:pl-2 last:pr-5 py-1 border w-full whitespace-nowrap">
                          {attendee?.firstName} {attendee?.lastName}
                          <p className="text-[13px] text-gray-500 mymd:hidden">
                            {attendee?.email}
                          </p>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-1  w-full whitespace-nowrap hidden mymd:block border">
                          {attendee?.email}
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-1 border w-full whitespace-nowrap">
                          <div className="flex items-center gap-2">
                            <div
                              className="cursor-pointer"
                              onClick={() => handleEdit(attendee)}
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <mask
                                  id="mask0_1395_148"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="24"
                                  height="24"
                                >
                                  <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1395_148)">
                                  <path
                                    d="M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                                    fill="#C5C5C7"
                                  />
                                </g>
                              </svg>
                            </div>
                            <div
                              className="cursor-pointer"
                              onClick={() => handleDelete(attendee)}
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <mask
                                  id="mask0_1395_2879"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="24"
                                  height="24"
                                >
                                  <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1395_2879)">
                                  <path
                                    d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.021 20.8043 17.55 21 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                    fill="#C5C5C7"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          <button
            type="submit"
            className="mt-3 w-[230px] flex h-[35px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[15px]"
            onClick={() => setClickedButton("notSubmit")}
          >
            Add new group attendee
          </button>
          <div className="flex gap-3 mt-4">
            <button
              type="button"
              onClick={() => {
                setStep(3);
              }}
              className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
            >
              Previous
            </button>
            <button
              type="submit"
              className="w-[110px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
              onClick={() => setClickedButton("submit")}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form4;
