// 8.30-10.30
// 11-1.00
// 1.30-3.30
// 4-6

export const PreConferenceWorkshopsINR = [
  {
    label:
      "Critical Care Nutriton Simplified (8.30 am - 10.15 am, CORAL) - ₹600/-",
    value: 1003,
    price: 600,
  },
  {
    label:
      "Dietary fibre in India- An unmet need/ The PROF Talk (8.30 am - 10.30 am, OYSTER) - ₹600/-",
    value: 10033,
    price: 600,
  },

  {
    label:
      "Muscle Loss and Malnutrition- The Dual Challenge in Clinical Practice (10.30 am - 12.30 pm, CORAL) - ₹600/-",
    value: 10044,
    price: 600,
  },
  {
    label:
      "Let Nutritionists be the Primary Care Provider (11.00 am - 01.00 pm, OYSTER) - ₹600/-",
    value: 1004,
    price: 600,
  },
  {
    label:
      "Trouble Shooting Nutrition Challenges: A Case Based Approach (01.30 pm - 03.30 pm, CORAL) - ₹600/-",
    value: 10040,
    price: 600,
  },
  {
    label: "Type I Diabetes (01.30 pm - 04.30 pm, OYSTER) - ₹600/-",
    value: 100400,
    price: 600,
  },
  {
    label:
      "Demystifying Dysphagia: Enhancing patient care & improving quality with new edge approach (03.15 pm - 05.15 pm, CORAL) - ₹600/-",
    value: 10050,
    price: 600,
  },
];

export const PreConferenceWorkshopsUSD = [
  {
    label:
      "Critical Care Nutriton Simplified (8.30 am - 10.15 am, CORAL) - $25/-",
    value: 1003,
    price: 25,
  },
  {
    label:
      "Dietary fibre in India- An unmet need/ The PROF Talk (8.30 am - 10.30 am, OYSTER) - $25/-",
    value: 10033,
    price: 25,
  },

  {
    label:
      "Muscle Loss and Malnutrition- The Dual Challenge in Clinical Practice (10.30 am - 12.30 pm, CORAL) - $25/-",
    value: 10044,
    price: 25,
  },
  {
    label:
      "Let Nutritionists be the Primary Care Provider (11.00 am - 01.00 pm, OYSTER) - $25/-",
    value: 1004,
    price: 25,
  },
  {
    label:
      "Trouble Shooting Nutrition Challenges: A Case Based Approach (01.30 pm - 03.30 pm, CORAL) - $25/-",
    value: 10040,
    price: 25,
  },
  {
    label: "Type I Diabetes (01.30 pm - 04.30 pm, OYSTER) - $25/-",
    value: 100400,
    price: 25,
  },
  {
    label:
      "Demystifying Dysphagia: Enhancing patient care & improving quality with new edge approach (03.15 pm - 05.15 pm, CORAL) - $25/-",
    value: 10050,
    price: 25,
  },
  // {
  //   label: "Enteral Nutrition (8.30 am - 10.30 am) - $25/-",
  //   value: 1003,
  //   price: 25,
  // },
  // {
  //   label: "CGM Workshop (11.00 am - 01.00 pm) - $25/-",
  //   value: 1004,
  //   price: 25,
  // },
  // {
  //   label:
  //     "Research and Publications in Critical Care (01.30 pm - 03.30 pm) - $25/-",
  //   value: 10040,
  //   price: 25,
  // },
  // {
  //   label: "Counselling Skills for Dietitians (04.00 pm - 06.00 pm) - $25/-",
  //   value: 10050,
  //   price: 25,
  // },
];
