import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setPaymentData } from "../../redux/stores/reducers/paymentSlice";

const Form6 = ({ setTriggerPopup, setStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const cartData = useSelector((state) => state.cartSlice);
  const userData = useSelector((state) => state.userSlice.formData);
  const [eventDetails, setEventDetails] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [gstIn, setGstIn] = useState("");
  const [code1, setCode1] = useState("");
  const [isRegistrationConfirmed, setRegistrationConfirmed] = useState(false);
  const [isAgreementConfirmed, setAgreementConfirmed] = useState(false);
  const [isVerified, setIsVerified] = useState("none");
  const [gstDetails, setGstDetails] = useState({
    companyName: "",
    address: "",
    pinCode: "",
    city: "",
    state: "",
  });
  const [isUSD, setIsUSD] = useState(false);
  let eventId = "6534239bf28e2db28c8ca3ca";
  // let eventId = "64777259c7ecf7eebb4ea505";
  // let eventId = "652f9010471a95b362d04d98";
  const totalCartValue = useSelector((state) => state.userSlice.totalCartValue);

  const getEventData = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/event/${eventId}`)
      .then(function (response) {
        setEventDetails(response?.data?.savedEventConfig);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let confrenceName = cartData?.registrationCart[0]?.name;
    if (confrenceName === "Foreign Delegate - USD 200/-") {
      setIsUSD(true);
      // setDiscount(125);
    }
    if (
      confrenceName === "IAPEN Member - ₹8,200/-" ||
      confrenceName === "Non-IAPEN Member - ₹10,200/-"
    ) {
      setIsUSD(false);
      // setDiscount(3600);
    }

    if (confrenceName === "Student - ₹4,700/-") {
      setIsUSD(false);
      // setDiscount(1700);
    }
  }, [cartData]);

  async function onFormSubmit(data, error) {
    if (isVerified === "success") {
      for (const value of Object.values(gstDetails)) {
        if (value === "") {
          dispatch(
            setShowToast({
              message: "Please complete all fields",
              category: "danger",
            })
          );
          return;
        }
      }
    }

    if (gstIn !== "" && isVerified !== "success") {
      dispatch(
        setShowToast({
          message: "Enter valid GSTIN or empty the field",
          category: "danger",
        })
      );
      return;
    }
    if (!isAgreementConfirmed || !isRegistrationConfirmed) {
      dispatch(
        setShowToast({
          message: "Please confirm to all term and conditions",
          category: "danger",
        })
      );
      return;
    }

    setIsSubmitting(true);
    let additionalData = {};
    if (Object.keys(data).length > 6) {
      for (const prop in data) {
        if (
          prop !== "firstName" &&
          prop !== "lastName" &&
          prop !== "mobile" &&
          prop !== "email" &&
          prop !== "organization" &&
          prop !== "jobTitle" &&
          prop !== "country" &&
          prop !== "state" &&
          prop !== "city" &&
          prop !== "countryCode" &&
          prop !== "countryCode2" &&
          prop !== "value" &&
          prop !== "profilePicture"
        ) {
          additionalData[prop] = data[prop];
        }
      }
    }

    const updatedAdditionalData = Object.keys(additionalData).reduce(
      (transformedObj, key) => {
        const transformedKey = key
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()
          .replace(/[^a-zA-Z ]/g, "")
          .replace(/\s/g, "_");
        transformedObj[transformedKey] = additionalData[key];
        return transformedObj;
      },
      {}
    );
    // let ticketId = "652f92b4471a95b362d05077";

    // let ticketId = "65573568b3d2c335e84d1b67"; //test ticktet ID
    let ticketId = "6535f52ff28e2db28c8ccf25"; //MAIN
    // "652f92b4471a95b362d05077"
    const id =
      Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;
    const transactionId =
      ticketId.substring(0, 6) + eventId.substring(0, 6) + id;
    const paymentAmount = totalCartValue;
    let code = `${
      code1 +
      `${
        eventDetails?.attendees?.length > 0
          ? eventDetails?.attendees?.length + 1
          : 1
      }`
    }`;
    let users = {
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: parseInt(data.mobile),
      countryCode: data?.countryCode || "+91",
      email: data.email,
      eventId: eventId,
      jobTitle: data.occupation,
      organization: data.companyName,
      country: { name: data.country },
      state: { name: data.state },
      city: { name: data.city },
      profilePicture: data?.profilePicture,
      code: code,
      additionalData: {
        ...updatedAdditionalData,
        gala_dinner: cartData?.galaDinnerCart?.length > 0 ? "Yes" : "No",
        total_amount: totalCartValue,
        iapen_badge_code: code,
        gst_details: [
          { label: `GSTIN: ${gstIn || "N/A"}` },
          {
            label: `CompanyName: ${
              gstDetails?.companyName?.trim()?.replace(/\s*,\s*/g, " - ") ||
              "N/A"
            }`,
          },
          {
            label:
              gstDetails?.address?.trim() !== ""
                ? `Address: ${
                    gstDetails?.address?.trim()?.replace(/\s*,\s*/g, " - ") ||
                    "N/A"
                  } ${gstDetails?.city
                    ?.trim()
                    ?.replace(/\s*,\s*/g, " - ")} ${gstDetails?.state
                    ?.trim()
                    ?.replace(/\s*,\s*/g, " - ")} ${gstDetails?.pinCode
                    ?.trim()
                    ?.replace(/\s*,\s*/g, " - ")}`
                : `Address: N/A`,
          },
        ],
        payment_agreement: [
          { label: `Registration Agreement: ${isRegistrationConfirmed}` },
          {
            label: `Particulars Modification Agreement: ${isAgreementConfirmed}`,
          },
        ],
        payment_details: [
          { label: `Status: Success` },
          {
            label: `Amount: ${totalCartValue}`,
          },
          {
            label: `Transaction id: ${transactionId}`,
          },
        ],
      },
    };

    const verifiedUser = await userValidation(users);
    if (!verifiedUser) {
      dispatch(
        setShowToast({
          message: "You have already registered for the event",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    let paymentData = {
      eventId: eventId,
      ticketId: ticketId,
      amount: paymentAmount,
      // amount: 2,
      transactionId: transactionId,
      userData: users,
      couponId: "",
      discount: 0,
    };

    let userData;
    userData = paymentData.userData;

    let razorpayDetails = {
      ...paymentData,
      userData: userData,
      billing: {},
      currency: isUSD ? "USD" : "INR",
    };

    dispatch(setPaymentData(razorpayDetails));
    navigate("/payments/checkout");

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/user/initiatePaymentEmail`,
        {
          data: paymentData,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  const sortData = (data) => {
    if (data) {
      const updatedArray = Object.values(data);
      return updatedArray || [];
    }
  };
  useEffect(() => {
    if (isVerified === "pending") {
      let gstInRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      if (!gstInRegex.test(gstIn)) {
        setIsVerified("pending");
      } else {
        setIsVerified("success");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gstIn]);

  const handleGSTDetails = (e) => {
    setGstDetails({
      ...gstDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const randomFourDigit = Math.floor(1000 + Math.random() * 9000);

    if (
      cartData?.galaDinnerCart?.length > 0 &&
      userData?.preConferenceWorkshop !== "Yes" &&
      userData?.attendLllWorkshop !== "Yes"
    ) {
      setCode1(`MG00 - ${randomFourDigit}`);
    } else if (
      cartData?.galaDinnerCart?.length === 0 &&
      userData?.preConferenceWorkshop === "Yes" &&
      userData?.attendLllWorkshop !== "Yes"
    ) {
      setCode1(`MP00 - ${randomFourDigit}`);
    } else if (
      cartData?.galaDinnerCart?.length > 0 &&
      userData?.preConferenceWorkshop === "Yes" &&
      userData?.attendLllWorkshop !== "Yes"
    ) {
      setCode1(`MPG0 - ${randomFourDigit}`);
    } else if (
      cartData?.galaDinnerCart?.length > 0 &&
      userData?.preConferenceWorkshop !== "Yes" &&
      userData?.attendLllWorkshop === "Yes"
    ) {
      setCode1(`MGL0 - ${randomFourDigit}`);
    } else if (
      cartData?.galaDinnerCart?.length === 0 &&
      userData?.preConferenceWorkshop === "Yes" &&
      userData?.attendLllWorkshop === "Yes"
    ) {
      setCode1(`MPL0 - ${randomFourDigit}`);
    } else if (
      cartData?.galaDinnerCart?.length > 0 &&
      userData?.preConferenceWorkshop === "Yes" &&
      userData?.attendLllWorkshop === "Yes"
    ) {
      setCode1(`MPGL - ${randomFourDigit}`);
    } else if (
      cartData?.galaDinnerCart?.length === 0 &&
      userData?.preConferenceWorkshop !== "Yes" &&
      userData?.attendLllWorkshop === "Yes"
    ) {
      setCode1(`ML00 - ${randomFourDigit}`);
    } else if (
      cartData?.galaDinnerCart?.length === 0 &&
      userData?.preConferenceWorkshop !== "Yes" &&
      userData?.attendLllWorkshop !== "Yes"
    ) {
      setCode1(`M000 - ${randomFourDigit}`);
    } else {
      setCode1(`M000 - ${randomFourDigit}`);
    }
  }, [cartData, userData]);

  const userValidation = async (users) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/attendee/checkGroup`,
        {
          eventId: eventId,
          users: [users],
        }
      );

      if (response?.data?.success === false) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      dispatch(
        setShowToast({
          message: `Sorry, something went wrong`,
          category: "danger",
        })
      );
      return false;
    }
  };

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <div className="mymd:w-[500px]">
        <div className="text-black text-[20px] font-[600] mb-5">
          Summary
          <p className="text-[14px] font-[400] mt-1">
            Please verify your final selection
          </p>
        </div>

        <div className="w-full mb-4">
          <div className="text-black text-[17px] font-[600] mb-1">
            Main Conference
          </div>
          {cartData?.registrationCart?.map((data, index) => {
            return (
              <div
                className="mymd:flex mymd:w-[500px] justify-between items-center"
                key={index}
              >
                <div className="mymd:w-[350px]">{data?.name}</div>
                <div className="font-[600]">
                  {isUSD ? "$" : "₹"}
                  {data?.price?.toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>

        <div className="w-full mb-4">
          {cartData?.galaDinnerCart?.map((data, index) => {
            return (
              <>
                <div className="text-black text-[17px] font-[600] mb-1">
                  Gala Dinner
                </div>
                <div
                  className="mymd:flex mymd:w-[500px] justify-between items-center"
                  key={index}
                >
                  <div className="mymd:w-[350px]">{data?.name}</div>
                  <div className="font-[600]">
                    {isUSD ? "$" : "₹"}
                    {data?.price?.toLocaleString()}
                  </div>
                </div>
              </>
            );
          })}
        </div>

        {userData?.preConferenceWorkshop === "Yes" && (
          <div className="w-full mb-4">
            <div className="text-black text-[17px] font-[600] mb-1">
              Pre-Conference Workshop
            </div>
            {sortData(cartData?.preConfrenceWorkshopCart[0])?.map(
              (option, index) => {
                return (
                  <React.Fragment key={index}>
                    {option?.value && (
                      <div className="mymd:flex mymd:w-[500px] justify-between items-start mb-2">
                        <div className="mymd:w-[350px]">{option?.label}</div>
                        <div className="font-[600]">
                          {isUSD ? "$" : "₹"}
                          {option?.price?.toLocaleString()}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              }
            )}
          </div>
        )}

        {userData?.attendLllWorkshop === "Yes" && (
          <div className="w-full mb-4">
            <div className="text-black text-[17px] font-[600] mb-1">
              LLL Course
            </div>
            {sortData(cartData?.sessionsCart[0])?.map((courses, index) => {
              return (
                <React.Fragment key={index}>
                  {courses?.id && (
                    <div className="mymd:flex mymd:w-[500px] justify-between items-start mb-2">
                      <div className="mymd:w-[350px]">{courses?.label}</div>
                      <div className="font-[600]">
                        {isUSD ? "$" : "₹"}
                        {courses?.price?.toLocaleString()}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}

        <div className="mymd:flex flex-col mymd:w-[500px] font-[600]  border-t-2 border-b-2 mt-2 py-1.5 ">
          {/* <div className="mymd:flex justify-between items-center mymd:w-[500px]">
            <div>Total</div>
            <div className="font-[600]">
              {isUSD ? "$" : "₹"}
              {totalCartValue?.toLocaleString()}
            </div>
          </div> */}

          {/* <div className="mymd:flex justify-between items-center mymd:w-[500px] text-green-600 mt-2 mb-3 border-b-2 pb-1.5">
            <div>Early Bird Discount</div>
            <div className="font-[600]">
              {isUSD ? "-$" : "-₹"}
              {discount?.toLocaleString()}
            </div>
          </div> */}

          <div className="mymd:flex justify-between items-center mymd:w-[500px] text-[17px]">
            <div>
              Grand Total{" "}
              <span className="text-[12px]">{!isUSD && "(Incl. of GST)"}</span>
            </div>
            <div className="font-[600]">
              {isUSD ? "$" : "₹"}
              {(totalCartValue - discount).toLocaleString()}
            </div>
          </div>
        </div>

        <div className="my-4">
          <div className="flex mt-3 items-start mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              id="isRegistrationConfirmed"
              className="form-checkbox mt-1"
              checked={isRegistrationConfirmed}
              required
              onChange={() =>
                setRegistrationConfirmed(!isRegistrationConfirmed)
              }
            />

            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="isRegistrationConfirmed"
            >
              I confirm my registration for the above programs.{" "}
              <span className="text-rose-500">*</span>
            </label>
          </div>

          <div className="flex mt-3 items-start mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              required
              id="isAgreementConfirmed"
              className="form-checkbox mt-1"
              checked={isAgreementConfirmed}
              onChange={() => setAgreementConfirmed(!isAgreementConfirmed)}
            />

            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="isAgreementConfirmed"
            >
              I agree, once payment is made, the workshops selected and other
              conference particulars cannot be changed.{" "}
              <span className="text-rose-500">*</span>
            </label>
          </div>

          {/* <label className="flex items-start mt-1 ">
            <div className="">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={isRegistrationConfirmed}
                required
                onChange={() =>
                  setRegistrationConfirmed(!isRegistrationConfirmed)
                }
              />
            </div>
            <div
              className={`text-[13px] font-medium ml-2 mt-[1px] cursor-pointer`}
            >
              <span>
                I confirm my registration for the above programs.{" "}
                <span className="text-rose-500">*</span>
              </span>
            </div>
          </label> */}

          {/* <label className="flex items-start mt-1 ">
            <div className="">
              <input
                type="checkbox"
                required
                className="form-checkbox"
                checked={isAgreementConfirmed}
                onChange={() => setAgreementConfirmed(!isAgreementConfirmed)}
              />
            </div>
            <div
              className={`text-[13px] font-medium ml-2 mt-[1px] cursor-pointer`}
            >
              <span>
                I agree, once payment is made, the workshops selected and other
                conference particulars cannot be changed.{" "}
                <span className="text-rose-500">*</span>
              </span>
            </div>
          </label> */}
        </div>

        {!isUSD && (
          <div className="mt-3">
            <div className="text-black text-[16px] font-[600] mb-1">
              GST Details (optional)
            </div>

            <div className="mb-3 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="17"
                fill="gray"
                viewBox="0 -960 960 960"
                width="17"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
              <span className="text-[13px] ml-1 text-gray-500">
                THE GST DETAILS CANNOT BE UPDATED AT A LATER DATE
              </span>
            </div>

            <div className="mymd:w-[244px] mb-4">
              <label
                className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                htmlFor="GSTIN"
              >
                GSTIN
              </label>
              <input
                id="GSTIN"
                className="form-input mymd:w-[500px] w-full"
                type="text"
                value={gstIn}
                placeholder="GSTIN"
                onChange={(e) => {
                  setGstIn(e.target.value);
                  setIsVerified("pending");
                }}
              />
              {isVerified === "pending" && (
                <p className="mt-2 text-[13px] text-danger">Invalid GSTIN</p>
              )}
            </div>

            {isVerified === "success" && (
              <>
                <div className="mymd:w-[244px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                    htmlFor="companyName"
                  >
                    Company Name <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="companyName"
                    className="form-input mymd:w-[500px] w-full"
                    type="text"
                    value={gstDetails?.companyName}
                    name="companyName"
                    onChange={handleGSTDetails}
                    required
                    placeholder="Company Name"
                  />
                </div>

                <div className="mymd:w-[500px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                    htmlFor="address"
                  >
                    Address <span className="text-rose-500">*</span>
                  </label>
                  <textarea
                    id="address"
                    rows={3}
                    required
                    value={gstDetails?.address}
                    name="address"
                    onChange={handleGSTDetails}
                    cols={50}
                    className="whitespace-pre-line-wrap peer bg-[#ebeced] text-gray-900 text-[15px] rounded-[10px] w-full py-2 px-3"
                    placeholder="Your Address"
                  ></textarea>
                </div>

                <div className="mymd:w-[500px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                    htmlFor="pinCode"
                  >
                    Pin Code <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="pinCode"
                    className="form-input w-full"
                    type="text"
                    required
                    maxLength={6}
                    minLength={3}
                    value={gstDetails?.pinCode}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d+$/.test(inputValue) || inputValue === "") {
                        setGstDetails({ ...gstDetails, pinCode: inputValue });
                      }
                    }}
                    placeholder="Pin Code"
                  />
                </div>

                <div className="mymd:w-[500px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                    htmlFor="city"
                  >
                    City <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="city"
                    className="form-input w-full"
                    type="text"
                    required
                    value={gstDetails?.city}
                    name="city"
                    onChange={handleGSTDetails}
                    placeholder="City"
                  />
                </div>

                <div className="mymd:w-[500px] mb-4">
                  <label
                    className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                    htmlFor="state"
                  >
                    State <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="state"
                    value={gstDetails?.state}
                    name="state"
                    onChange={handleGSTDetails}
                    className="form-input w-full"
                    type="text"
                    required
                    placeholder="State"
                  />
                </div>
              </>
            )}
          </div>
        )}

        <div className="flex gap-3 mt-4">
          <button
            type="button"
            onClick={() => setStep(5)}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            onClick={() => {
              onFormSubmit(userData);
            }}
            className="w-[150px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            {isSubmitting ? "Loading..." : "Make payment"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form6;
