import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addGroupData } from "../../redux/stores/reducers/userSlice";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";

const Form2 = ({ setStep }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm({ mode: "onChange" });

  const onSubmit = (data) => {
    if (data.privacyPolicyConsent !== "Yes") {
      dispatch(
        setShowToast({
          message: "Please accept data protection and privacy policy",
          category: "danger",
        })
      );
      return;
    }

    dispatch(
      addGroupData({
        ...data,
        isDelegateList: data?.isDelegateList === false ? "No" : "Yes",
        personalDataConsent: data?.personalDataConsent === false ? "No" : "Yes",
      })
    );
    localStorage.setItem("groupForm2", JSON.stringify({ ...data }));
    setStep(3);
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("groupForm2"));
    if (formData) {
      reset({ ...formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="text-black text-[17px] font-[600] mb-0 pr-[0px] mymd:w-[500px] ">
          Data Protection Consent:
          <div className="flex mt-3 items-start mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              className="form-checkbox mt-1"
              required
              value="Yes"
              id="privacyPolicyConsent"
              {...register("privacyPolicyConsent")}
            />
            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="privacyPolicyConsent"
            >
              I understand and hereby accept that my personal data will be kept
              confidential as per data protection and privacy policy.{" "}
              <span className="text-rose-500">*</span>
            </label>
          </div>
          <div className="flex mt-3 items-start mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              className="form-checkbox mt-1"
              id="personalDataConsent"
              value="Yes"
              {...register("personalDataConsent")}
            />

            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="personalDataConsent"
            >
              I consent for my data to be shared with exhibitors and sponsors
              when attending a sponsored symposium or connecting on a booth.
              Sponsors and exhibitors will then be able to contact me for
              commercial or marketing purposes.
            </label>
          </div>
          <div className="flex mt-3 items-start mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              className="form-checkbox mt-1"
              id="isDelegateList"
              value="Yes"
              {...register("isDelegateList")}
            />

            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="isDelegateList"
            >
              I want to be listed on the delegate list of ESPEN 2023.
            </label>
          </div>
        </div>
        <div className="font-[500] mt-4">
          Note:
          <p>- GST included for all prices</p>
          <p className="my-1">
            - Pre-conference topics and timings are subjected to change.
          </p>
          <p>
            - For any assistance please contact:{" "}
            <span className="text-primary">+91 85910 76985</span>
          </p>
        </div>
        <div className="flex gap-3 mt-4 ">
          <button
            type="button"
            onClick={() => setStep(1)}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            className="w-[110px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form2;
