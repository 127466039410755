import React, { useEffect, useState } from "react";
import Select from "../../common/Select";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addUserData } from "../../redux/stores/reducers/userSlice";
import {
  addGalaDinner,
  addPreConfrenceWorkshops,
  addRegistrationProduct,
  addSessions,
  resetCartSlice,
} from "../../redux/stores/reducers/cartSlice";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";
import axios from "axios";
import {
  PreConferenceWorkshopsINR,
  PreConferenceWorkshopsUSD,
} from "../../partials/PreConferenceWorkshops";

const Form4 = ({ setStep }) => {
  const { register, handleSubmit, reset, setValue, watch } = useForm({
    mode: "onChange",
  });
  const [workShopOptions, setWorkShopOptions] = useState([]);
  const [uplodedImage, setUplodedImage] = useState("");
  const dispatch = useDispatch();
  const [iapenMemberNo, setIapenMemberNo] = useState("LM-");

  const options2 = [
    { label: "IAPEN Member - ₹8,200/-", value: 1, price: 8200 },
    { label: "Non-IAPEN Member - ₹10,200/-", value: 4, price: 10200 },
    // { label: "Student - ₹4,700/-", value: 3, price: 4700 },
    { label: "Foreign Delegate - USD 200/-", value: 2, price: 200 },
  ];

  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);

  const onSubmit = async (data) => {
    let updatedData;
    const membershipNumberPattern = /^LM-\d{4}-\d{1,4}$/;

    if (!value2?.label) {
      dispatch(
        setShowToast({
          message: "Please complete all fields",
          category: "danger",
        })
      );
      return;
    }

    if (
      data?.attendAs?.value === 1 &&
      !membershipNumberPattern.test(iapenMemberNo)
    ) {
      dispatch(
        setShowToast({
          message: "Invalid IAPEN Member No.",
          category: "danger",
        })
      );
      return;
    }

    if (
      data?.attendAs?.value === 1 &&
      membershipNumberPattern.test(iapenMemberNo)
    ) {
      const [, year] = iapenMemberNo.split("-");
      const yearNumber = parseInt(year, 10);
      if (yearNumber < 1950 || yearNumber > 2024) {
        dispatch(
          setShowToast({
            message: "Invalid IAPEN Member No.",
            category: "danger",
          })
        );
        return;
      }
    }

    let newData = { ...data };

    if (data.preConferenceWorkshop === "Yes" && value1?.length === 0) {
      dispatch(
        setShowToast({
          message: "Please complete all fields",
          category: "danger",
        })
      );
      return;
    }

    if (
      data?.attendAs?.value === 2 ||
      data?.attendAs?.value === 4 ||
      data?.attendAs?.value === 3
    ) {
      let removeArray = ["espnBlockMember", "iapenMemberNo"];
      removeArray.forEach((key) => {
        delete newData[key];
      });
    }

    if (data?.preConferenceWorkshop === "No") {
      let removeArray = ["attendingWorkShops"];
      removeArray.forEach((key) => {
        delete newData[key];
      });
    }

    if (data?.attendAs?.value === 1) {
      newData = { ...newData, iapenMemberNo: iapenMemberNo };
    }

    if (data?.attendAs?.value === 3) {
      if (uplodedImage !== "") {
        updatedData = {
          ...newData,
          galaDinner: newData?.galaDinner === "Yes" ? "Yes" : "No",
          attendingAs: value2?.label,
          studentIdProof: uplodedImage,
          attendingWorkShops: value1,
        };
        dispatch(addUserData(updatedData));
        localStorage.setItem(
          "form4",
          JSON.stringify({ ...newData, attendingWorkShops: value1 })
        );
        setStep(5);
      } else {
        dispatch(
          setShowToast({
            message: "Please upload image to continue",
            category: "danger",
          })
        );
      }
    } else {
      updatedData = {
        ...newData,
        galaDinner: newData?.galaDinner === "Yes" ? "Yes" : "No",
        attendingAs: value2?.label,
        attendingWorkShops: value1,
      };
      dispatch(addUserData(updatedData));
      localStorage.setItem(
        "form4",
        JSON.stringify({ ...newData, attendingWorkShops: value1 })
      );
      setStep(5);
    }
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("form4"));
    if (formData) {
      reset({
        espnBlockMember: formData?.espnBlockMember,
        galaDinner: formData?.galaDinner === "Yes" ? "Yes" : false,
        preConferenceWorkshop: formData?.preConferenceWorkshop,
      });
      setIapenMemberNo(
        formData?.iapenMemberNo ? formData?.iapenMemberNo : "LM-"
      );
      setValue("attendingWorkShops", formData?.attendingWorkShops);
      setValue("attendAs", formData?.attendAs);
      setValue1(
        formData?.attendingWorkShops ? formData?.attendingWorkShops : []
      );
      setValue2(formData?.attendAs);
      dispatch(
        addRegistrationProduct({
          name: formData?.attendAs?.label,
          price: formData?.attendAs?.price,
          id: 1001,
          qty: 1,
        })
      );
      dispatch(
        addGalaDinner({
          checked: formData?.galaDinner,
          currency: formData?.attendAs?.value === 2 ? "USD" : "INR",
        })
      );
    } else {
      setIapenMemberNo("LM-");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watch().preConferenceWorkshop === "Yes") {
      dispatch(addPreConfrenceWorkshops({ data: value1 }));
    } else {
      dispatch(addPreConfrenceWorkshops({ data: [] }));
    }
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value1, watch().preConferenceWorkshop]);

  useEffect(() => {
    if (watch().preConferenceWorkshop === "No") {
      setValue("attendingWorkShops", []);
      setValue1([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch().preConferenceWorkshop]);

  useEffect(() => {
    dispatch(addSessions({ data: [] }));
    localStorage.removeItem("form5");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch().espnBlockMember]);

  const handleAttendAs = (data) => {
    dispatch(resetCartSlice());
    reset({
      galaDinner: false,
      preConferenceWorkshop: "No",
    });
    dispatch(
      addGalaDinner({
        checked: false,
      })
    );
    setValue1([]);
    setValue("attendAs", data);
    setValue2(data);
    localStorage.removeItem("form5");
    dispatch(
      addRegistrationProduct({
        name: data?.label,
        price: data?.price,
        id: 1001,
        qty: 1,
      })
    );
  };

  useEffect(() => {
    if (value2?.value === 2) {
      setWorkShopOptions(PreConferenceWorkshopsUSD);
    } else {
      setWorkShopOptions(PreConferenceWorkshopsINR);
    }
  }, [value2]);

  const handleCourseChange = (workshop) => {
    const workshopIndex = value1.findIndex(
      (item) => item.value === workshop.value
    );
    if (workshopIndex === -1) {
      setValue("attendingWorkShops", workshop);
      setValue1([...value1, workshop]);
    } else {
      const updatedworksops = value1.filter(
        (item) => item.value !== workshop.value
      );
      setValue("attendingWorkShops", updatedworksops);
      setValue1(updatedworksops);
    }
  };

  const handleIapenMemberNoChange = (e) => {
    let value = e.target.value;

    if (/^LM-\d{4}$/.test(value)) {
      value += "-";
    }

    if (value.length > "LM-9998-9955".length) {
      value = value.slice(0, "LM-9998-9955".length);
    }

    setIapenMemberNo(value);
  };

  const handleBackspace = (e) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      e.preventDefault();
      setIapenMemberNo(iapenMemberNo.slice(0, -1));
    }
  };

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <form
        onSubmit={handleSubmit((data) => onSubmit(data))}
        className="mymd:w-[500px]"
      >
        <div className="mb-6">
          <div className="text-black text-[16px] font-[600] mb-2">
            Main Conference:
          </div>

          <div className="text-[15px] font-[400] text-gray-500 mb-4">
            <label
              htmlFor="attendAs"
              className="block text-[15px] font-[400] mb-1 text-gray-500"
            >
              Select Category: <span className="text-rose-500">*</span>
            </label>
            <Select
              register={register}
              id={"attendAs"}
              options={options2}
              value={value2}
              required={true}
              onChange={(data) => {
                handleAttendAs(data);
              }}
            />
          </div>

          {value2?.label === "IAPEN Member - ₹8,200/-" && (
            <div className="mymd:w-[500px] mb-4 mt-0">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="iapenMemberNo"
              >
                IAPEN Membership Number (e.g. LM-2021-016, LM-2020-17)
                <span className="text-rose-500">*</span>
              </label>
              <input
                id="iapenMemberNo"
                className="form-input w-full"
                type="text"
                required
                value={iapenMemberNo}
                onChange={handleIapenMemberNoChange}
                onKeyDown={handleBackspace}
                placeholder="IAPEN Member No."
              />
            </div>
          )}

          {value2?.label === "IAPEN Member - ₹8,200/-" && (
            <div className="text-[15px] font-[400] text-gray-500 ">
              <p className="mt-0">
                Are you a ESPEN Block Member 2023?{" "}
                <span className="text-rose-500">*</span>
              </p>
              <div className="flex items-center gap-3 mt-2 mb-4">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    id="espnBlockMember"
                    required
                    className="mr-1 cursor-pointer"
                    value={"Yes"}
                    {...register("espnBlockMember")}
                  />
                  Yes
                </label>
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    id="espnBlockMember"
                    required
                    className="mr-1 cursor-pointer"
                    value={"No"}
                    {...register("espnBlockMember")}
                  />
                  No
                </label>
              </div>
            </div>
          )}

          {value2?.label === "Student - ₹4,700/-" && (
            <div className="mymd:w-[500px] mb-4 mt-0">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="studentIdProof"
              >
                Student ID proof <span className="text-rose-500">*</span>
              </label>
              <div className="cursor-pointer rounded-lg">
                <input
                  id={"studentIdProof"}
                  type="file"
                  className="form-input w-full"
                  onChange={(e) => {
                    const formData = new FormData();
                    formData.append("file", e.target.files[0]);

                    axios
                      .post(
                        `${process.env.REACT_APP_SERVER_URL}/user/upload_picture?ContentType=image/png`,
                        formData
                      )
                      .then(function (response) {
                        setUplodedImage(response.data.url);
                      })
                      .catch(function (error) {
                        console.log(error);
                        dispatch(
                          setShowToast({
                            message: "Error uploading image",
                            category: "danger",
                          })
                        );
                      });
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="text-black text-[16px] font-[600] mb-2">
          Gala Dinner:
        </div>

        <label className="flex items-start my-2" htmlFor="galaDinner">
          <input
            type="checkbox"
            className="form-checkbox mt-1"
            id="galaDinner"
            value="Yes"
            {...register("galaDinner")}
            onChange={(e) => {
              dispatch(
                addGalaDinner({
                  checked: e.target.checked,
                  currency: value2?.value === 2 ? "USD" : "INR",
                })
              );
            }}
          />
          <div className="text-[15px] font-[400] text-gray-500 ml-2 cursor-pointer">
            <span className="">
              Would you like to attend the Bollywood Saga - Gala Dinner? -{" "}
              {value2?.value === 2 ? "(USD 40)" : "(₹4,500/-)"}{" "}
            </span>
          </div>
        </label>

        <div className="text-black text-[16px] font-[600] mt-6 mb-2">
          Pre-Conference Workshop (8.00 am-6.00 pm): <br />
          9th February 2024
        </div>

        <div className="text-[15px] font-[400] text-gray-500 ">
          <p className="mt-0">
            Will you attend any pre-conference workshops?{" "}
            <span className="text-rose-500">*</span>
          </p>
          <div className="flex items-center gap-3 mt-2 mb-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                id="preConferenceWorkshop"
                className="mr-1 cursor-pointer"
                value={"Yes"}
                required
                {...register("preConferenceWorkshop")}
              />
              Yes
            </label>
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                id="preConferenceWorkshop"
                className="mr-1 cursor-pointer"
                value={"No"}
                required
                {...register("preConferenceWorkshop")}
              />
              No
            </label>
          </div>
        </div>

        <div className="">
          <div className="ml-0 mt-2">
            {workShopOptions?.map((workshop) => {
              return (
                <label className="flex items-start mt-2" key={workshop?.value}>
                  <div className="">
                    <input
                      disabled={
                        watch().preConferenceWorkshop !== "Yes" ? true : false
                      }
                      type="checkbox"
                      register={register}
                      id={"attendingWorkShops"}
                      className="form-checkbox"
                      checked={value1.some(
                        (data) => data.value === workshop.value
                      )}
                      onChange={() => {
                        handleCourseChange(workshop);
                      }}
                    />
                  </div>
                  <div
                    className={`text-[14px] font-medium ml-2 ${
                      watch().preConferenceWorkshop !== "Yes"
                        ? "text-gray-500"
                        : "cursor-pointer"
                    } `}
                  >
                    <span>{workshop?.label}</span>
                  </div>
                </label>
              );
            })}
          </div>
          {/* <label className="block text-[15px] font-[400] mb-1 text-gray-500">
              Select the pre conference workshops that you will be attending{" "}
              <span className="text-rose-500">*</span>
            </label>
            <Select
              multiple
              required
              register={register}
              id={"attendingWorkShops"}
              options={value2?.value === 2 ? optionsUSD : options}
              value={value1}
              onChange={(data) => {
                setValue("attendingWorkShops", data);
                setValue1(data);
                // handleWorkshopChange(data);
              }}
            /> */}
        </div>
        <div className="font-[500] mt-4">
          Note:
          <p>- GST included for all prices</p>
          <p className="my-1">
            - Pre-conference topics and timings are subjected to change.
          </p>
          <p>
            - For any assistance please contact:{" "}
            <span className="text-primary">+91 85910 76985</span>
          </p>
        </div>
        <div className="flex gap-3 mt-4">
          <button
            type="button"
            onClick={() => setStep(3)}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            className="w-[110px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form4;
