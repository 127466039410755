import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducers/userSlice";
import cartSlice from "./reducers/cartSlice";
import toastSlice from "./reducers/toastSlice";
import groupCartSlice from "./reducers/groupCartSlice";
import paymentSlice from "./reducers/paymentSlice";

export default configureStore({
  reducer: {
    userSlice: userSlice,
    cartSlice: cartSlice,
    toastSlice: toastSlice,
    groupCartSlice: groupCartSlice,
    paymentSlice: paymentSlice,
  },
});
