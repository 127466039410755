import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addGroupData } from "../../redux/stores/reducers/userSlice";
import CountryDropdown from "../../common/CountryDropdown";
import StateDropdown from "../../common/StateDropdown";
import CityDropwdown from "../../common/CityDropdown";

const Form3 = ({ setStep }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const [countryResult, setCountryResult] = useState([]);
  const [cityResult, setCityResult] = useState([]);
  const [stateResult, setStateResult] = useState([]);

  const onSubmit = (data) => {
    let obj = {
      ...data,
      address: data?.address.trim()?.replace(/\s*,\s*/g, " - "),
      country: countryResult?.name?.trim(),
      state: stateResult?.name?.trim(),
      city: cityResult?.name?.trim(),
    };
    dispatch(addGroupData(obj));
    localStorage.setItem(
      "groupForm3",
      JSON.stringify({ ...data, countryResult, stateResult, cityResult })
    );
    setStep(4);
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("groupForm3"));
    if (formData) {
      reset({ address: formData?.address });
      setCountryResult(formData?.countryResult);
      setStateResult(formData?.stateResult);
      setCityResult(formData?.cityResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="mymd:w-[500px] mymd:flex w-[100%] justify-center">
          <div className="w-full flex flex-col justify-center">
            <div className="mymd:w-[500px] mb-4">
              <label
                className="block text-[15px] font-[400] mb-1 text-gray-500"
                htmlFor="address"
              >
                Postal Address for Correspondence (with pin code){" "}
                <span className="text-rose-500">*</span>
              </label>
              <textarea
                {...register("address", {
                  required: "Address is required",
                  validate: (value) => {
                    const trimmedValue = value.trim();
                    if (trimmedValue === "") {
                      return "Please enter a valid address";
                    }
                    return true;
                  },
                })}
                id="address"
                rows={3}
                required
                cols={50}
                className="whitespace-pre-line-wrap peer bg-[#ebeced] text-gray-900 text-[15px] rounded-[10px] w-full py-2 px-3"
                placeholder="Your Address"
              ></textarea>
              {errors?.address && (
                <p className="text-red-500 text-[13px] font-medium mt-1">
                  {errors?.address?.message}
                </p>
              )}
            </div>
            <CountryDropdown
              countryResult={countryResult}
              setCountryResult={setCountryResult}
            />

            <StateDropdown
              countryResult={countryResult}
              stateResult={stateResult}
              setStateResult={setStateResult}
            />

            <CityDropwdown
              setCityResult={setCityResult}
              cityResult={cityResult}
              countryResult={countryResult}
              stateResult={stateResult}
            />
          </div>
        </div>
        <div className="font-[500]">
          Note:
          <p>- GST included for all prices</p>
          <p className="my-1">
            - Pre-conference topics and timings are subjected to change.
          </p>
          <p>
            - For any assistance please contact:{" "}
            <span className="text-primary">+91 85910 76985</span>
          </p>
        </div>
        <div className="flex gap-3 mt-3">
          <button
            type="button"
            onClick={() => {
              setStep(2);
            }}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            // onClick={() => setStep(4)}
            className="w-[110px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form3;
