import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserData,
  setIsEdit,
  setRegisterLeader,
} from "../../redux/stores/reducers/userSlice";
import Select from "../../common/Select";
import { Occupations } from "../../partials/Occupations";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";
import axios from "axios";
import countryCodes from "../../partials/CountryCodes.json";
import CountryCode from "../../common/CountryCode";

const Form5 = ({ setStep }) => {
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("+91");
  const [countryCode2, setCountryCode2] = useState("+91");
  const [uplodedImage, setUplodedImage] = useState("");
  const [allCountryCode, setAllCountryCode] = useState(countryCodes);
  const groupData = useSelector((state) => state.userSlice.groupFormData);
  const userData = useSelector((state) => state.userSlice.formData);
  const isEdit = useSelector((state) => state.userSlice.isEdit);
  const [emailValidation, setEmailValidation] = useState(false);

  const isRegisterLeader = useSelector(
    (state) => state.userSlice.registerLeader
  );
  const [value1, setValue1] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (isEdit) {
      reset({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        mobile: userData?.mobile,
        email: userData?.email,
        espenLllUsername: userData?.espenLllUsername,
        educationQualification: userData?.educationQualification,
        nationality: userData?.nationality,
        espenPortalEmail: userData?.espenPortalEmail,
        companyName: userData?.companyName,
        contactNumber: userData?.contactNumber,
        occupation:
          userData?.occupationDropDown?.value === 8 ? userData?.occupation : "",
      });
      setValue1(userData?.occupationDropDown);
      setCountryCode(userData?.countryCode);
      setCountryCode2(userData?.countryCode2);
      setUplodedImage(userData?.profilePicture);
    } else if (userData?.email) {
      reset({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        mobile: userData?.mobile,
        email: userData?.email,
        companyName: userData?.companyName,
        contactNumber: userData?.contactNumber,
        occupation:
          userData?.occupationDropDown?.value === 8 ? userData?.occupation : "",
      });
      setValue1(userData?.occupationDropDown);
      setCountryCode(userData?.countryCode);
      setCountryCode2(userData?.countryCode2);
      setUplodedImage(userData?.profilePicture);
    } else if (isRegisterLeader) {
      const formData = JSON.parse(localStorage.getItem("groupForm1"));
      if (formData) {
        reset({ ...formData });
        setValue1(formData?.occupationDropDown);
        setCountryCode(formData?.countryCode);
        setCountryCode2(formData?.countryCode2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isRegisterLeader]);

  const onSubmit = (data) => {
    if (!value1?.label) {
      dispatch(
        setShowToast({
          message: "Please complete all fields",
          category: "danger",
        })
      );
      return;
    }

    const userExits = groupData?.groupAttendees?.some(
      (attendee) =>
        attendee?.email.toLowerCase().trim() ===
        data?.email.toLowerCase().trim()
    );
    if (!userExits || isEdit) {
      const updatedData =
        value1?.label !== "Other"
          ? {
              ...data,
              firstName: data?.firstName?.trim(),
              lastName: data?.lastName?.trim(),
              espenPortalEmail: data?.espenPortalEmail?.trim() || "",
              companyName: data?.companyName
                ?.trim()
                ?.replace(/\s*,\s*/g, " - "),
              nationality: data?.nationality
                ?.trim()
                ?.replace(/\s*,\s*/g, " - "),
              espenLllUsername: data?.espenLllUsername?.trim(),
              occupation: value1?.label,
              occupationDropDown: value1,
              email: data.email.toLowerCase().trim(),
              countryCode2: countryCode2,
              countryCode: countryCode,
              profilePicture: uplodedImage ? uplodedImage : "",
              educationQualification:
                data?.educationQualification
                  ?.trim()
                  ?.replace(/\s*,\s*/g, " - ") || "",
            }
          : {
              ...data,
              firstName: data?.firstName?.trim(),
              lastName: data?.lastName?.trim(),
              espenPortalEmail: data?.espenPortalEmail?.trim() || "",
              companyName: data?.companyName
                ?.trim()
                ?.replace(/\s*,\s*/g, " - "),
              nationality: data?.nationality
                ?.trim()
                ?.replace(/\s*,\s*/g, " - "),
              espenLllUsername: data?.espenPortalEmail?.trim(),
              occupation: data?.occupation?.trim(),
              occupationDropDown: value1,
              email: data.email.toLowerCase().trim(),
              countryCode2: countryCode2,
              countryCode: countryCode,
              profilePicture: uplodedImage ? uplodedImage : "",
              educationQualification:
                data?.educationQualification
                  ?.trim()
                  ?.replace(/\s*,\s*/g, " - ") || "",
            };
      let eventId = "6534239bf28e2db28c8ca3ca";

      if (!isEdit) {
        axios
          .post(`${process.env.REACT_APP_SERVER_URL}/attendee/checkGroup`, {
            eventId: eventId,
            users: [updatedData],
          })
          .then(function (response) {
            if (response?.data?.success === false) {
              dispatch(
                setShowToast({
                  message: "Attendee already registered for the event",
                  category: "danger",
                })
              );
            } else {
              dispatch(addUserData(updatedData));
              setStep(6);
            }
          })
          .catch(function (error) {
            dispatch(
              setShowToast({
                message: `Sorry, something went wrong`,
                category: "danger",
              })
            );
          });
      } else {
        dispatch(addUserData(updatedData));
        setStep(6);
      }
    } else {
      dispatch(
        setShowToast({
          message: "User already exits in the group",
          category: "danger",
        })
      );
    }
  };

  const handleImageUpload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/user/upload_picture?ContentType=image/png`,
        formData
      )
      .then(function (response) {
        setUplodedImage(response.data.url);
      })
      .catch(function (error) {
        console.log(error);
        setUplodedImage("");
        dispatch(
          setShowToast({
            message: "Error uploading image",
            category: "danger",
          })
        );
      });
  };

  const isYahooEmail = (email) => {
    return !/@yahoo\./i.test(email);
  };

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="mymd:flex justify-between ">
          <div className="mymd:w-[244px] mb-6">
            <label
              className="block text-[15px] font-[400] mb-1 text-gray-500"
              htmlFor="firstName"
            >
              First Name <span className="text-rose-500">*</span>
            </label>
            <input
              id="firstName"
              className="form-input w-[100%]"
              type="text"
              required
              placeholder="First Name"
              {...register("firstName", {
                required: "First Name is required",
                validate: (value) => {
                  const trimmedValue = value.trim();
                  if (trimmedValue === "") {
                    return "Please enter a valid first name";
                  }
                  return true;
                },
              })}
            />
          </div>

          <div className="mymd:w-[243px] mb-6">
            <label
              className="block text-[15px] font-[400] mb-1 text-gray-500"
              htmlFor="lastName"
            >
              Last Name <span className="text-rose-500">*</span>
            </label>
            <input
              id="lastName"
              className="form-input w-full"
              type="text"
              required
              placeholder="Last Name"
              {...register("lastName", {
                required: "Last Name is required",
                validate: (value) => {
                  const trimmedValue = value.trim();
                  if (trimmedValue === "") {
                    return "Please enter a valid last name";
                  }
                  return true;
                },
              })}
            />
          </div>
        </div>

        <div>
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="mobile"
          >
            WhatsApp Number <span className="text-rose-500">*</span>
          </label>
          <div className="flex gap-2 items-center mb-6">
            <CountryCode
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              allCountryCode={allCountryCode}
              setAllCountryCode={setAllCountryCode}
            />

            <div className="w-full">
              <input
                id="mobile"
                className="form-input w-full"
                type="tel"
                required
                maxLength={10}
                placeholder="WhatsApp Number"
                inputMode="numeric"
                {...register("mobile")}
              />
            </div>
          </div>
        </div>

        <div>
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="mobile"
          >
            Contact Number <span className="text-rose-500">*</span>
          </label>
          <div className="flex gap-1 items-center mb-6">
            <CountryCode
              countryCode={countryCode2}
              setCountryCode={setCountryCode2}
              allCountryCode={allCountryCode}
              setAllCountryCode={setAllCountryCode}
            />
            <div className="w-full">
              <input
                id="contactNumber"
                className="form-input w-full"
                type="tel"
                required
                maxLength={10}
                placeholder="Contact Number"
                inputMode="numeric"
                {...register("contactNumber")}
              />
            </div>
          </div>
        </div>

        <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="email"
          >
            Email <span className="text-rose-500">*</span> <br />
            <span className="text-[13px] ">
              (Used for event registration and payment confirmation)
            </span>
          </label>

          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                validEmail: (value) =>
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    value
                  ) || "Invalid email format",
                notYahooEmail: (value) =>
                  isYahooEmail(value) ||
                  "Sorry, we do not support Yahoo emails, for assistance contact: +91 8591076985",
              },
            }}
            render={({ field }) => (
              <div>
                <input
                  type="text"
                  {...field}
                  className="form-input w-full"
                  placeholder="Email"
                />
                <p className="text-danger text-[13px] font-medium mt-2">
                  {errors?.email && errors?.email?.message}
                </p>
              </div>
            )}
          />
        </div>

        <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="espenPortalEmail"
          >
            Email{" "}
            <span className="text-[13px] text-primary">
              (Only for Participants Registering for ESPEN LLL Course)
            </span>
            <br />
            <span className="text-[13px] ">
              (Used for ESPEN LLL Registration on the ESPEN LLL Online portal-
              <span
                className="cursor-pointer underline text-primary"
                onClick={() =>
                  window.open(`https://lllnutrition.com`, "_blank")
                }
              >
                https://lllnutrition.com/
              </span>{" "}
              . TO NOTE- Registration on the above portal is FREE but MANDATORY)
            </span>
          </label>
          <Controller
            name="espenPortalEmail"
            control={control}
            rules={{
              validate: {
                validEmail: (value) => {
                  if (!value) return true;
                  return (
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                      value
                    ) || "Invalid email format"
                  );
                },
              },
            }}
            render={({ field }) => (
              <div>
                <input
                  type="text"
                  {...field}
                  className="form-input w-full"
                  placeholder="Email"
                />
                <p className="text-danger text-[13px] font-medium mt-2">
                  {errors?.espenPortalEmail &&
                    errors?.espenPortalEmail?.message}
                </p>
              </div>
            )}
          />
        </div>

        {/* <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="email"
          >
            Email <span className="text-rose-500">*</span> <br />
            <span className="text-[13px] ">
              (Used for event registration and payment confirmation)
            </span>
          </label>
          <input
            id="email"
            className="form-input w-full"
            type="email"
            required
            placeholder="Email"
            {...register("email")}
          />
          {emailValidation && (
            <p className="text-danger text-[13px] font-medium mt-2">
              Sorry, we do not support Yahoo email addresses.
            </p>
          )}
        </div>

        <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="espenPortalEmail"
          >
            Email <br />
            <span className="text-[13px] ">
              (Used for ESPEN LLL Registration on the ESPEN LLL Online portal-
              <span
                className="cursor-pointer underline text-primary"
                onClick={() =>
                  window.open(`https://lllnutrition.com`, "_blank")
                }
              >
                https://lllnutrition.com/
              </span>{" "}
              . TO NOTE- Registration on the above portal is FREE but MANDATORY)
            </span>
          </label>
          <input
            id="espenPortalEmail"
            className="form-input w-full"
            type="email"
            placeholder="Email"
            {...register("espenPortalEmail")}
          />
        </div> */}

        <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="companyName"
          >
            AFFILIATION <span className="text-rose-500">*</span> <br />
            <span className="text-[13px]">
              (Name of Organization/Institute/Private Practice/Self employed)
            </span>
          </label>
          <input
            id="companyName"
            className="form-input w-full"
            type="text"
            required
            placeholder="Company Name"
            {...register("companyName", {
              required: "This field is required",
              validate: (value) => {
                const trimmedValue = value.trim();
                if (trimmedValue === "") {
                  return "Please enter a valid text";
                }
                return true;
              },
            })}
          />
        </div>

        <div className="text-[15px] font-[400] text-gray-500 mb-4">
          <label
            htmlFor="occupation"
            className="block text-[15px] font-[400] mb-1 text-gray-500"
          >
            Occupation <span className="text-rose-500">*</span>
          </label>
          <Select
            id="occupation"
            options={Occupations}
            value={value1}
            required={true}
            onChange={(data) => {
              setValue1(data);
            }}
          />
        </div>

        {value1?.label === "Other" && (
          <div className="mymd:w-[500px] mb-6">
            <input
              id="occupation"
              className="w-full border-b border-gray-300 focus:border-primary outline-none bg-white py-1 px-2"
              type="text"
              required
              placeholder="Occupation"
              {...register("occupation", {
                required: "This field is required",
                validate: (value) => {
                  const trimmedValue = value.trim();
                  if (trimmedValue === "") {
                    return "Please enter a valid text";
                  }
                  return true;
                },
              })}
            />
          </div>
        )}
        <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="espenLllUsername"
          >
            ESPEN LLL USERNAME{" "}
            <span className="text-[13px] text-primary">
              (Only for Participants Registering for ESPEN LLL Course)
            </span>
            <br />
            <span className="text-[13px]">
              (The same as used by you on ESPEN LLL portal log in)(for new
              users, you may create the username on ESPEN LLL portal -
              <span
                className="cursor-pointer underline text-primary"
                onClick={() =>
                  window.open(`https://lllnutrition.com`, "_blank")
                }
              >
                https://lllnutrition.com/
              </span>{" "}
              and share here)
            </span>
          </label>
          <input
            id="espenLllUsername"
            className="form-input w-[100%]"
            type="text"
            placeholder="ESPEN LLL Username"
            {...register("espenLllUsername")}
          />
        </div>

        <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="educationQualification"
          >
            HIGHEST EDUCATION QUALIFICATION&nbsp;
            <span className="text-rose-500">*</span>
          </label>
          <input
            id="educationQualification"
            className="form-input w-[100%]"
            type="text"
            required
            placeholder="HIGHEST EDUCATION QUALIFICATION"
            {...register("educationQualification", {
              required: "This field is required",
              validate: (value) => {
                const trimmedValue = value.trim();
                if (trimmedValue === "") {
                  return "Please enter a valid text";
                }
                return true;
              },
            })}
          />
        </div>

        <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="nationality"
          >
            Nationality&nbsp;
            <span className="text-rose-500">*</span>
          </label>
          <input
            id="nationality"
            className="form-input w-[100%]"
            type="text"
            required
            placeholder="NATIONALITY"
            {...register("nationality", {
              required: "This field is required",
              validate: (value) => {
                const trimmedValue = value.trim();
                if (trimmedValue === "") {
                  return "Please enter a valid text";
                }
                return true;
              },
            })}
          />
        </div>
        <div className={`mymd:w-[500px] mb-6 mt-0`}>
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="studentIdProof"
          >
            Profile Picture{" "}
            <span className="text-[13px]">
              (Recommended size - 500 X 500 pixels)
            </span>
          </label>
          <div className="">
            {uplodedImage ? (
              <label htmlFor="file" className="w-[40px] cursor-pointer">
                <div className="border border-gray-500 rounded-full w-[40px] h-[40px]">
                  <img
                    src={uplodedImage}
                    alt="profilePic"
                    className="object-cover"
                  />
                  <input
                    id={"file"}
                    type="file"
                    className={`hidden`}
                    onChange={handleImageUpload}
                  />
                </div>
              </label>
            ) : (
              <input
                id={"studentIdProof"}
                type="file"
                className={`form-input mymd:w-${
                  uplodedImage ? "[500px]" : "full"
                } w-full cursor-pointer`}
                onChange={handleImageUpload}
              />
            )}

            {/* {uplodedImage && (
              <div className="border border-gray-500 rounded-full w-[40px] h-[40px]">
                <img src={uplodedImage} alt="profilePic" className="" />
             </div>
             )} */}
          </div>
        </div>
        <div className="font-[500]">
          Note:
          <p>- GST included for all prices</p>
          <p className="my-1">
            - Pre-conference topics and timings are subjected to change.
          </p>
          <p>
            - For any assistance please contact:{" "}
            <span className="text-primary">+91 85910 76985</span>
          </p>
        </div>
        <div className="flex gap-3 mt-3">
          <button
            type="button"
            onClick={() => {
              dispatch(setIsEdit({ value: false }));
              dispatch(setRegisterLeader({ value: false }));
              setStep(4);
            }}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            className="w-[110px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form5;
