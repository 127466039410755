import { Provider } from "react-redux";
import "./App.css";
import Home from "./components/home/Home";
import store from "./redux/stores/store";
import Toast from "./common/Toast";
import { Routes, Route } from "react-router-dom";
import PaymentStatusMessage from "./components/paymentPages/PaymentStatusMessage";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import PaymentCheckout from "./components/paymentPages/PaymentCheckout";

function App() {
  return (
    <Provider store={store}>
      {/* <Home /> */}
      <Toast />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/payments/:platform/status"
          element={<PaymentStatusMessage />}
        />
        <Route path="/payments/checkout" element={<PaymentCheckout />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Provider>
  );
}

export default App;
