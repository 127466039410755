import React, { Fragment, useEffect, useRef } from "react";
import FormSteps from "./FormSteps";
import AllForms from "./AllForms";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { calculateCartValue } from "../../redux/stores/reducers/userSlice";
import { totalSteps } from "../../partials/Steps";

const RegisterForm = ({ step, setStep, setTriggerPopup, triggerPopup }) => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cartSlice);
  const totalCartValue = useSelector((state) => state.userSlice.totalCartValue);
  const allIndividualFormsRef = useRef(null);
  useEffect(() => {
    dispatch(calculateCartValue({ data: cartData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  useEffect(() => {
    if (allIndividualFormsRef.current) {
      allIndividualFormsRef.current.scrollTop = 0;
    }
  }, [step]);

  return (
    <>
      <Transition appear show={triggerPopup} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={() => undefined}>
          <div className="fixed inset-0 w-full ">
            <div className="w-full mymd:w-auto absolute mymd:right-[60px] mymd:top-[20px] z-50 transition-all duration-300 bg-white top-0 mymd:pt-0 py-[20px] border-b shadow pl-[10px] mymd:border-0 mymd:pl-0 mymd:shadow-none min-h-[72px]">
              {step !== 6 && (
                <div className="flex items-center mymd:bg-gray-100 px-1.5 py-1 rounded-[10px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mymd:w-[20px] mymd:h-[20px] w-[23px] h-[23px]"
                    viewBox="0 -960 960 960"
                  >
                    <path d="M286.788-81Q257-81 236-102.212q-21-21.213-21-51Q215-183 236.212-204q21.213-21 51-21Q317-225 338-203.788q21 21.213 21 51Q359-123 337.788-102q-21.213 21-51 21Zm400 0Q657-81 636-102.212q-21-21.213-21-51Q615-183 636.212-204q21.213-21 51-21Q717-225 738-203.788q21 21.213 21 51Q759-123 737.788-102q-21.213 21-51 21ZM235-741l110 228h288l125-228H235Zm-30-60h589.074q22.964 0 34.945 21Q841-759 829-738L694-495q-11 19-28.559 30.5Q647.881-453 627-453H324l-56 104h491v60H277q-42 0-60.5-28t.5-63l64-118-152-322H51v-60h117l37 79Zm140 288h288-288Z" />
                  </svg>
                  <div className="mymd:text-[13px] text-[15px] ml-1">
                    {cartData?.registrationCart[0]?.name ===
                    "Foreign Delegate - USD 200/-" ? (
                      <span className="font-[600] text-primary">
                        ${totalCartValue?.toLocaleString()}
                      </span>
                    ) : (
                      <span className="font-[600] text-primary">
                        ₹{totalCartValue?.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div
              className={`absolute mymd:right-[25px] top-[17px] bg-gray-200 w-[90px] h-[35px] z-50 right-[55px] rounded-full mymd:hidden justify-around items-center flex`}
            >
              {step !== 1 && (
                <div
                  className=""
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="26"
                    viewBox="0 -960 960 960"
                    width="26"
                  >
                    <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
                  </svg>
                </div>
              )}

              <div className="text-[14px] font-[500] rounded-full bg-white w-[50px] h-[25px] grid place-items-center">
                {step} of {totalSteps?.length}
              </div>
            </div>
            <div
              className="absolute mymd:right-[25px] top-[20px] rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px] z-50 right-[17px]"
              onClick={() => setTriggerPopup(false)}
            >
              <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px] font-medium">
                x
              </div>
            </div>

            <div
              className={`mymd:w-[80%] h-[140px] w-full z-40 fixed  md:left-0 md:right-0 mx-auto md:top-[0px] bg-white hidden mymd:block`}
            >
              <FormSteps setStep={setStep} step={step} />
            </div>
            <div
              className="w-[100vw] mymd:w-full h-full overflow-auto scroll-smooth	bg-white scrollbar-hide mymd:scrollbar-default"
              ref={allIndividualFormsRef}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform bg-white transition-all ">
                  <div className="flex justify-center w-full mt-[60px] mymd:mt-[125px] mb-[60px]">
                    <AllForms
                      step={step}
                      setStep={setStep}
                      setTriggerPopup={setTriggerPopup}
                      triggerPopup={triggerPopup}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default RegisterForm;
