import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addSessions } from "../../redux/stores/reducers/cartSlice";
import {
  category1CourseOptions,
  category2CourseOptions,
  category3CourseOptions,
} from "../../partials/CourseOptions";
import {
  addAttendeeGroupData,
  editFormData,
  setIsEdit,
  setRegisterLeader,
} from "../../redux/stores/reducers/userSlice";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";
import {
  addToGroupCart,
  editGroupCart,
} from "../../redux/stores/reducers/groupCartSlice";

const Form8 = ({ setStep }) => {
  const groupData = useSelector((state) => state.userSlice.groupFormData);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userSlice.formData);
  const isEdit = useSelector((state) => state.userSlice.isEdit);
  const cartData = useSelector((state) => state.cartSlice);
  const totalCartValue = useSelector((state) => state.userSlice.totalCartValue);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [courseOptions, setCourseOptions] = useState({
    title: "",
    options: [],
  });

  const { register, handleSubmit, watch, reset } = useForm({
    mode: "onChange",
  });

  const handleCourseChange = (course) => {
    const courseIndex = selectedCourses.findIndex(
      (item) => item.id === course.id
    );

    if (courseIndex === -1) {
      setSelectedCourses([...selectedCourses, course]);
    } else {
      const updatedSelectedCourses = selectedCourses.filter(
        (item) => item.id !== course.id
      );
      setSelectedCourses(updatedSelectedCourses);
    }
  };

  const onSubmit = (data) => {
    let obj;
    if (data?.attendWorkShop === "No") {
      obj = { ...userData, attendLllWorkshop: data?.attendWorkShop };
    } else {
      if (data?.attendWorkShop === "Yes" && selectedCourses?.length === 0) {
        dispatch(
          setShowToast({
            message: "Please choose either the LLL course or select 'No'.",
            category: "danger",
          })
        );
        return;
      }

      obj = {
        ...userData,
        lllCourses: selectedCourses,
        attendLllWorkshop: data?.attendWorkShop,
      };
    }

    const userExits = groupData?.groupAttendees?.some(
      (attendee) => attendee?.email === userData?.email
    );

    if (isEdit) {
      dispatch(editFormData(obj));
      dispatch(setIsEdit({ value: false }));
      dispatch(setRegisterLeader({ value: false }));
      const updatedCartData = {
        ...cartData,
        totalCartValue: totalCartValue,
        email: userData?.email,
        name: userData?.firstName + " " + userData?.lastName,
      };
      dispatch(editGroupCart(updatedCartData));

      setStep(4);
      dispatch(
        setShowToast({
          message: "Attendee edited successfully",
          category: "success",
        })
      );
    } else if (!userExits) {
      dispatch(addAttendeeGroupData(obj));
      dispatch(setRegisterLeader({ value: false }));
      const updatedCartData = {
        ...cartData,
        totalCartValue: totalCartValue,
        email: userData?.email,
        name: userData?.firstName + " " + userData?.lastName,
      };
      dispatch(addToGroupCart(updatedCartData));
      setStep(4);
      dispatch(
        setShowToast({
          message: "Attendee added successfully",
          category: "success",
        })
      );
    } else {
      dispatch(
        setShowToast({
          message: "User already exits in the group",
          category: "danger",
        })
      );
    }
  };

  useEffect(() => {
    if (
      userData?.attendAs?.value === 1 &&
      userData?.espnBlockMember === "Yes"
    ) {
      setCourseOptions({
        options: category1CourseOptions?.options,
        title: category1CourseOptions?.title,
      });
    } else if (
      userData?.attendAs?.value === 1 &&
      userData?.espnBlockMember === "No"
    ) {
      setCourseOptions({
        options: category2CourseOptions?.options,
        title: category2CourseOptions?.title,
      });
    } else if (
      userData?.attendAs?.value === 2 ||
      userData?.attendAs?.value === 4 ||
      userData?.attendAs?.value === 3
    ) {
      setCourseOptions({
        options: category3CourseOptions?.options,
        title: category3CourseOptions?.title,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (isEdit) {
      reset({
        attendWorkShop: userData?.attendLllWorkshop,
      });
      setSelectedCourses(userData?.lllCourses);
    } else if (userData?.lllCourses) {
      reset({
        attendWorkShop: userData?.attendLllWorkshop,
      });
      setSelectedCourses(userData?.lllCourses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    dispatch(addSessions({ data: selectedCourses }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourses]);

  useEffect(() => {
    if (watch().attendWorkShop !== "Yes") {
      setSelectedCourses([]);
    }
  }, [watch().attendWorkShop]);

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <form
        onSubmit={handleSubmit((data) => onSubmit(data))}
        className="mymd:w-[500px]"
      >
        <div className="text-[15px] font-[400] text-gray-500 ">
          <p className="text-black text-[17px] font-[600] mt-5 pr-[50px]">
            Would you like to attend LLL Workshop?{" "}
            <span className="text-rose-500">*</span>
          </p>
          <div className="flex items-center gap-3 mt-2 mb-4">
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                id="attendWorkShop"
                className="mr-1 cursor-pointer"
                value={"Yes"}
                required
                {...register("attendWorkShop")}
                disabled
              />
              Yes
            </label>
            <label className="flex items-center cursor-pointer">
              <input
                type="radio"
                id="attendWorkShop"
                className="mr-1 cursor-pointer"
                value={"No"}
                required
                {...register("attendWorkShop")}
                disabled
              />
              No
            </label>
          </div>
          <p className="text-rose-500 text-[14px]">
            Registration for the LLL Workshops has been closed.
          </p>
        </div>
        <div className="text-black text-[15px] font-[400] mt-5 pr-[50px]">
          <span className="font-[500]">Showing for: </span>
          {courseOptions?.title}
        </div>

        <div className="text-black text-[17px] font-[600] mt-5 pr-[50px]">
          8th February 2024 - Day 1
          <div className="ml-2 mt-2">
            {courseOptions?.options?.slice(0, 2).map((course, index) => {
              return (
                <label className="flex items-start mt-1" key={index}>
                  <div className="">
                    <input
                      type="checkbox"
                      disabled={watch().attendWorkShop !== "Yes" ? true : false}
                      className="form-checkbox"
                      onChange={() => handleCourseChange(course)}
                      checked={selectedCourses.some(
                        (selectedCourse) => selectedCourse.id === course.id
                      )}
                    />
                  </div>
                  <div
                    className={`text-[13px] font-medium ml-2 ${
                      watch().attendWorkShop !== "Yes"
                        ? "text-gray-500"
                        : "cursor-pointer"
                    } mt-0.5`}
                  >
                    <span>{course?.label}</span>
                  </div>
                </label>
              );
            })}
          </div>
        </div>

        <div className="text-black text-[17px] font-[600] mt-5 pr-[50px]">
          9th February 2024 - Day 2
          <div className="ml-2 mt-2">
            {courseOptions?.options?.slice(2, 4).map((course, index) => {
              return (
                <label className="flex items-start mt-1" key={index}>
                  <div>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      disabled={watch().attendWorkShop !== "Yes" ? true : false}
                      onChange={() => handleCourseChange(course)}
                      checked={selectedCourses.some(
                        (selectedCourse) => selectedCourse.id === course.id
                      )}
                    />
                  </div>
                  <div
                    className={`text-[13px] font-medium ml-2 ${
                      watch().attendWorkShop !== "Yes"
                        ? "text-gray-500"
                        : "cursor-pointer"
                    } mt-0.5`}
                  >
                    <span>{course?.label}</span>
                  </div>
                </label>
              );
            })}
          </div>
        </div>

        <div className="flex gap-2">
          {courseOptions.options?.map((course, index) => {
            return (
              <div
                className={`mymd:w-[100px] w-full h-[60px] border rounded-[10px] grid place-items-center mymd:text-[16px] font-[500] mt-[20px] transition-all duration-300 text-[15px] ${
                  selectedCourses?.length === index + 1
                    ? "shadow"
                    : "bg-gray-200 opacity-50 "
                } `}
                key={course?.id}
              >
                {index > 0 ? (
                  <p>{index + 1} topics</p>
                ) : (
                  <p>{index + 1} topic</p>
                )}

                <p>₹{(course?.price * (index + 1)).toLocaleString()}</p>
              </div>
            );
          })}
        </div>

        <div className="font-[500] mt-4">
          Note:
          <p>- GST included for all prices</p>
          <p className="my-1">
            - Pre-conference topics and timings are subjected to change.
          </p>
          <p>
            - For any assistance please contact:{" "}
            <span className="text-primary">+91 85910 76985</span>
          </p>
        </div>

        <div className="flex gap-3 mt-4 ">
          <button
            type="submit"
            onClick={() => setStep(7)}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            className="w-[110px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            {selectedCourses?.length > 0 ? "Next" : "Skip"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form8;
