import React from "react";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import Form4 from "./Form4";
import Form5 from "./Form5";
import Form6 from "./Form6";
import Form7 from "./Form7";
import Form8 from "./Form8";
import Form9 from "./Form9";

const AllGroupForms = ({ step, setStep, setTriggerPopup, triggerPopup }) => {
  switch (step) {
    case 1:
      return (
        <Form1
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
    case 2:
      return (
        <Form2
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
    case 3:
      return (
        <Form3
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
    case 4:
      return (
        <Form4
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
    case 5:
      return (
        <Form5
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
    case 6:
      return (
        <Form6
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
    case 7:
      return (
        <Form7
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
    case 8:
      return (
        <Form8
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
    case 9:
      return (
        <Form9
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );

    default:
      return (
        <Form1
          setStep={setStep}
          setTriggerPopup={setTriggerPopup}
          triggerPopup={triggerPopup}
        />
      );
  }
};

export default AllGroupForms;
