export const category1CourseOptions = {
  title: "IAPEN INDIA LIFE MEMBERS + ESPEN BLOCK MEMBERS 2023",
  options: [
    {
      label:
        "Topic 20: Nutrition and Prevention of Diseases - 9.00 am To 13:00 hrs",
      price: 4250,
      id: 1005,
    },
    {
      label:
        "Topic 18: Nutritional Support in Intensive Care Unit (ICU) Patients - 14:00 hrs To 18:00 hrs",
      price: 4250,
      id: 1006,
    },
    {
      label:
        "Topic 19: Chronic Intestinal Failure and Home Parenteral Nutrition (HPN) in Adults - 9.00 am To 13:00 hrs",
      price: 4250,
      id: 1007,
    },
    {
      label: "Topic 23: Nutrition in Obesity - 14:00 hrs To 18:00 hrs",
      price: 4250,
      id: 1008,
    },
  ],
};

export const category2CourseOptions = {
  title: "IAPEN INDIA LIFE MEMBER (BUT NOT ESPEN 2023 BLOCK MEMBER)",
  options: [
    {
      label:
        "Topic 20: Nutrition and Prevention of Diseases - 9.00 am To 13:00 hrs",
      price: 5000,
      id: 1009,
    },
    {
      label:
        "Topic 18: Nutritional Support in Intensive Care Unit (ICU) Patients - 14:00 hrs To 18:00 hrs",
      price: 5000,
      id: 1010,
    },
    {
      label:
        "Topic 19: Chronic Intestinal Failure and Home Parenteral Nutrition (HPN) in Adults - 9.00 am To 13:00 hrs",
      price: 5000,
      id: 1011,
    },
    {
      label: "Topic 23: Nutrition in Obesity - 14:00 hrs To 18:00 hrs",
      price: 5000,
      id: 1012,
    },
  ],
};

export const category3CourseOptions = {
  title: "NON-MEMBERS",
  options: [
    {
      label:
        "Topic 20: Nutrition and Prevention of Diseases - 9.00 am To 13:00 hrs",
      price: 6250,
      id: 1013,
    },
    {
      label:
        "Topic 18: Nutritional Support in Intensive Care Unit (ICU) Patients - 14:00 hrs To 18:00 hrs",
      price: 6250,
      id: 1014,
    },
    {
      label:
        "Topic 19: Chronic Intestinal Failure and Home Parenteral Nutrition (HPN) in Adults - 9.00 am To 13:00 hrs",
      price: 6250,
      id: 1015,
    },
    {
      label: "Topic 23: Nutrition in Obesity - 14:00 hrs To 18:00 hrs",
      price: 6250,
      id: 1016,
    },
  ],
};

export const category4CourseOptions = {
  title: "Foreign Delegate",
  options: [
    {
      label:
        "Topic 20: Nutrition and Prevention of Diseases - 9.00 am To 13:00 hrs",
      price: 70,
      id: 1017,
    },
    {
      label:
        "Topic 18: Nutritional Support in Intensive Care Unit (ICU) Patients - 14:00 hrs To 18:00 hrs",
      price: 70,
      id: 1018,
    },
    {
      label:
        "Topic 19: Chronic Intestinal Failure and Home Parenteral Nutrition (HPN) in Adults - 9.00 am To 13:00 hrs",
      price: 70,
      id: 1019,
    },
    {
      label: "Topic 23: Nutrition in Obesity - 14:00 hrs To 18:00 hrs",
      price: 70,
      id: 1020,
    },
  ],
};
