import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";
import axios from "axios";
import { resetGroupCartSlice } from "../../redux/stores/reducers/groupCartSlice";
import { useNavigate } from "react-router-dom";
import displayRazorpay from "../paymentPages/Razorpay";
import { setPaymentData } from "../../redux/stores/reducers/paymentSlice";

const Form9 = ({ setTriggerPopup, triggerPopup, setStep }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const groupData = useSelector((state) => state.userSlice.groupFormData);
  const groupCart = useSelector((state) => state.groupCartSlice.groupCart);
  const [gstIn, setGstIn] = useState("");
  const [isRegistrationConfirmed, setRegistrationConfirmed] = useState(false);
  const [isAgreementConfirmed, setAgreementConfirmed] = useState(false);
  const [isVerified, setIsVerified] = useState("none");
  const [eventDetails, setEventDetails] = useState([]);
  const [finalTotal, setFinalTotal] = useState(0);
  const [discount, setDiscount] = useState([]);
  const [gstDetails, setGstDetails] = useState({
    companyName: "",
    address: "",
    pinCode: "",
    city: "",
    state: "",
  });
  // let eventId = "6517b6b2810aa7d62feca614";
  let eventId = "6534239bf28e2db28c8ca3ca";
  // 64ec6e65e50e72dd8eb219c9 testing event
  const groupTotal = useSelector((state) => state.userSlice.groupTotal);
  useEffect(() => {
    if (isVerified === "pending") {
      let gstInRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      if (!gstInRegex.test(gstIn)) {
        setIsVerified("pending");
      } else {
        setIsVerified("success");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gstIn]);

  const getEventData = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/event/${eventId}`)
      .then(function (response) {
        setEventDetails(response?.data?.savedEventConfig);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortData = (data) => {
    const updatedArray = Object.values(data);
    return updatedArray || [];
  };

  async function onFormSubmit(data, error) {
    if (isVerified === "success") {
      for (const value of Object.values(gstDetails)) {
        if (value === "") {
          dispatch(
            setShowToast({
              message: "Please complete all fields",
              category: "danger",
            })
          );
          return;
        }
      }
    }

    if (gstIn !== "" && isVerified !== "success") {
      dispatch(
        setShowToast({
          message: "Enter valid GSTIN or empty the field",
          category: "danger",
        })
      );
      return;
    }

    if (!isAgreementConfirmed || !isRegistrationConfirmed) {
      dispatch(
        setShowToast({
          message: "Please confirm to all term and conditions",
          category: "danger",
        })
      );
      return;
    }
    setIsSubmitting(true);
    let ticketId = "6535f52ff28e2db28c8ccf25";
    const id =
      Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;
    const transactionId =
      ticketId.substring(0, 6) + eventId.substring(0, 6) + id;
    const paymentAmount = groupTotal;

    let users = data?.groupAttendees?.map((attendee, index) => {
      let code = "";
      const randomFourDigit = Math.floor(1000 + Math.random() * 9000);

      if (
        attendee?.galaDinner === "Yes" &&
        attendee?.preConferenceWorkshop !== "Yes" &&
        attendee?.attendLllWorkshop !== "Yes"
      ) {
        code =
          `MG00 - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      } else if (
        attendee?.galaDinner !== "Yes" &&
        attendee?.preConferenceWorkshop === "Yes" &&
        attendee?.attendLllWorkshop !== "Yes"
      ) {
        code =
          `MP00 - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      } else if (
        attendee?.galaDinner === "Yes" &&
        attendee?.preConferenceWorkshop === "Yes" &&
        attendee?.attendLllWorkshop !== "Yes"
      ) {
        code =
          `MPG0 - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      } else if (
        attendee?.galaDinner === "Yes" &&
        attendee?.preConferenceWorkshop !== "Yes" &&
        attendee?.attendLllWorkshop === "Yes"
      ) {
        code =
          `MGL0 - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      } else if (
        attendee?.galaDinner !== "Yes" &&
        attendee?.preConferenceWorkshop === "Yes" &&
        attendee?.attendLllWorkshop === "Yes"
      ) {
        code =
          `MPL0 - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      } else if (
        attendee?.galaDinner === "Yes" &&
        attendee?.preConferenceWorkshop === "Yes" &&
        attendee?.attendLllWorkshop === "Yes"
      ) {
        code =
          `MPGL - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      } else if (
        attendee?.galaDinner !== "Yes" &&
        attendee?.preConferenceWorkshop !== "Yes" &&
        attendee?.attendLllWorkshop === "Yes"
      ) {
        code =
          `ML00 - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      } else if (
        attendee?.galaDinner !== "Yes" &&
        attendee?.preConferenceWorkshop !== "Yes" &&
        attendee?.attendLllWorkshop !== "Yes"
      ) {
        code =
          `M000 - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      } else {
        code =
          `M000 - ${randomFourDigit}` +
          (eventDetails?.attendees?.length + 1 + index);
      }

      let additionalData = {};
      if (Object.keys(attendee).length > 6) {
        for (const prop in attendee) {
          if (
            prop !== "firstName" &&
            prop !== "lastName" &&
            prop !== "mobile" &&
            prop !== "email" &&
            prop !== "organization" &&
            prop !== "jobTitle" &&
            prop !== "country" &&
            prop !== "state" &&
            prop !== "city" &&
            prop !== "countryCode" &&
            prop !== "countryCode2" &&
            prop !== "value" &&
            prop !== "profilePicture"
          ) {
            additionalData[prop] = attendee[prop];
          }
        }
      }

      let updatedAdditionalData = Object.keys(additionalData).reduce(
        (transformedObj, key) => {
          const transformedKey = key
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()
            .replace(/[^a-zA-Z ]/g, "")
            .replace(/\s/g, "_");
          transformedObj[transformedKey] = additionalData[key];
          return transformedObj;
        },
        {}
      );

      let individualTotal = groupCart?.find(
        (cart) => cart?.email.toLowerCase() === attendee?.email.toLowerCase()
      );

      let finalObj = {
        firstName: attendee.firstName,
        lastName: attendee.lastName,
        mobile: parseInt(attendee.mobile),
        countryCode: attendee?.countryCode || "+91",
        email: attendee.email.toLowerCase(),
        eventId: eventId,
        jobTitle: attendee.occupation,
        organization: attendee.companyName,
        country: { name: attendee.country },
        state: { name: attendee.state },
        city: { name: attendee.city },
        code: code,
        profilePicture: attendee?.profilePicture,
        additionalData: {
          ...updatedAdditionalData,
          is_delegate_list: data?.isDelegateList,
          privacy_policy_consent: data?.privacyPolicyConsent,
          personal_data_consent: data?.personalDataConsent,
          group_name: data?.groupName,
          contact_number:
            attendee?.countryCode2 + " " + attendee?.contactNumber,
          iapen_badge_code: code,
          payment_agreement: [
            { label: `Registration Agreement: ${isRegistrationConfirmed}` },
            {
              label: `Particulars Modification Agreement: ${isAgreementConfirmed}`,
            },
          ],
          payment_details: [
            { label: `Status: Success` },
            {
              label: `Individual Total: Rs. ${individualTotal?.totalCartValue}`,
            },
            {
              label: `Group Total: Rs. ${groupTotal}`,
            },
            {
              label: `Transaction id: ${transactionId}`,
            },
          ],
          total_amount: individualTotal?.totalCartValue,
        },
      };
      return finalObj;
    });

    const verifiedUser = await userValidation(users);
    if (!verifiedUser) {
      dispatch(
        setShowToast({
          message: "Some group members are already registered for the event",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    let paymentData = {
      eventId: eventId,
      ticketId: ticketId,
      amount: paymentAmount,
      transactionId: transactionId,
      userData: users,
      couponId: "",
      discount: 0,
    };

    let razorpayDetails = {
      ...paymentData,
      userData: paymentData.userData,
      currency: "INR",
      billing: {
        address:
          data?.address +
          " " +
          data?.city +
          " " +
          data?.state +
          " " +
          data?.country,
        email: data?.email,
        name: data?.firstName + " " + data?.lastName,
        mobile: data?.mobile,
        countryCode: data?.countryCode,
        jobTitle: data?.occupation,
        organization: data?.companyName,
        groupName: data?.groupName,
      },
    };

    dispatch(setPaymentData(razorpayDetails));
    navigate("/payments/checkout");

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/user/initiatePaymentEmail`,
        {
          data: paymentData,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  const handleGSTDetails = (e) => {
    setGstDetails({
      ...gstDetails,
      [e.target.name]: e.target.value,
    });
  };

  const userValidation = async (users) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/attendee/checkGroup`,
        {
          eventId: eventId,
          users: users,
        }
      );
      if (response?.data?.success === false) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      dispatch(
        setShowToast({
          message: `Sorry, something went wrong`,
          category: "danger",
        })
      );
      return false;
    }
  };

  // const calculateDiscount = (groupdata) => {
  //   let discount = 0;
  //   let confrenceName = groupdata?.registrationCart[0]?.name;
  //   if (
  //     confrenceName === "IAPEN Member - ₹5,200/-" ||
  //     confrenceName === "Non-IAPEN Member - ₹6,200/-"
  //   ) {
  //     discount = 3600;
  //   }

  //   if (confrenceName === "Student - ₹4,700/-") {
  //     discount = 1700;
  //   }

  //   return discount;
  // };

  // const calTotalDiscount = () => {
  //   let totalVal = groupTotal;
  //   let totalDiscount = groupCart?.map((groupdata, index) => {
  //     let val = calculateDiscount(groupdata);
  //     totalVal = totalVal - val;
  //     return val;
  //   });
  //   setDiscount(totalDiscount);
  //   setFinalTotal(totalVal);
  //   return totalVal;
  // };

  // useEffect(() => {
  //   calTotalDiscount();
  // }, [groupTotal, groupCart]);

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <div className="mymd:w-[500px]">
        <div className="text-black text-[20px] font-[600] mb-5">
          Summary
          <p className="text-[14px] font-[400] mt-1">
            Please verify your final selection
          </p>
        </div>

        {groupCart?.map((groupdata, index) => {
          return (
            <React.Fragment key={index}>
              <div className="text-black text-[19px] font-[600] mb-2">
                {groupdata?.name}
              </div>
              <div className="w-full  mb-4">
                <div className="text-black text-[17px] font-[600] mb-1">
                  Main Conference
                </div>
                {groupdata?.registrationCart?.map((data) => {
                  return (
                    <div className="mymd:flex mymd:w-[500px] justify-between items-center mb-2.5">
                      <div className="mymd:w-[350px]">{data?.name}</div>
                      <div className="font-[600]">
                        ₹{data?.price?.toLocaleString()}
                      </div>
                    </div>
                  );
                })}
              </div>

              {groupdata?.galaDinnerCart?.length > 0 && (
                <div className="w-full mb-4">
                  <div className="text-black text-[17px] font-[600] mb-1">
                    Gala Dinner
                  </div>
                  {groupdata?.galaDinnerCart?.map((data, index) => {
                    return (
                      <div
                        className="mymd:flex mymd:w-[500px] justify-between items-center mb-2"
                        key={index}
                      >
                        <div className="mymd:w-[350px]">{data?.name}</div>
                        <div className="font-[600]">
                          ₹{data?.price?.toLocaleString()}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {sortData(groupdata?.preConfrenceWorkshopCart[0]).length > 1 && (
                <div className="w-full mb-4">
                  <div className="text-black text-[17px] font-[600] mb-1">
                    Pre-Conference Workshop
                  </div>
                  {sortData(groupdata?.preConfrenceWorkshopCart[0])?.map(
                    (option, index) => {
                      return (
                        <React.Fragment key={index}>
                          {option?.value && (
                            <div className="mymd:flex mymd:w-[500px] justify-between items-start mb-2">
                              <div className="mymd:w-[350px]">
                                {option?.label}
                              </div>
                              <div className="font-[600]">
                                ₹{option?.price?.toLocaleString()}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              )}

              {sortData(groupdata?.sessionsCart[0]).length > 1 && (
                <div className="w-full mb-4">
                  <div className="text-black text-[17px] font-[600] mb-1">
                    LLL Course
                  </div>
                  {sortData(groupdata?.sessionsCart[0])?.map(
                    (courses, index) => {
                      return (
                        <React.Fragment key={index}>
                          {courses?.id && (
                            <div className="mymd:flex mymd:w-[500px] justify-between items-start mb-2">
                              <div className="mymd:w-[350px]">
                                {courses?.label}
                              </div>
                              <div className="font-[600]">
                                ₹{courses?.price?.toLocaleString()}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              )}

              <div className="mymd:w-[500px] font-[500] mt-2 justify-between py-1 mb-3">
                {/* <div className="flex justify-between mymd:w-[500px]">
                  <div>Total - </div>
                  <div className="font-[500]">
                    ₹{groupdata?.totalCartValue?.toLocaleString()}
                  </div>
                </div>

                <div className="flex justify-between mymd:w-[500px] text-green-600 mt-1 ">
                  <div>Early Bird Discount - </div>
                  <div className="font-[500]">
                    -₹{discount[index]?.toLocaleString()}
                  </div>
                </div> */}

                <div className="flex justify-between mymd:w-[500px] py-1 border-y-2 mt-0">
                  <div>Total for {groupdata?.name} - </div>
                  <div className="font-[600]">
                    ₹{groupdata?.totalCartValue?.toLocaleString()}
                    {/* {(
                      groupdata?.totalCartValue - discount[index]
                    )?.toLocaleString()} */}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
        <div className="mymd:flex mymd:w-[500px] font-[500] border-b border-t mt-2 justify-between py-2 items-center">
          <div>
            Grand Total <span className="text-[12px]">(Incl. of GST) </span>{" "}
          </div>
          <div className="font-[600]">₹{groupTotal?.toLocaleString()}</div>
        </div>

        <div className="my-4">
          <div className="flex mt-3 items-start mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              id="isRegistrationConfirmed"
              className="form-checkbox mt-1"
              checked={isRegistrationConfirmed}
              required
              onChange={() =>
                setRegistrationConfirmed(!isRegistrationConfirmed)
              }
            />

            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="isRegistrationConfirmed"
            >
              I confirm my registration for the above programs.{" "}
              <span className="text-rose-500">*</span>
            </label>
          </div>

          <div className="flex mt-3 items-start mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              required
              id="isAgreementConfirmed"
              className="form-checkbox mt-1"
              checked={isAgreementConfirmed}
              onChange={() => setAgreementConfirmed(!isAgreementConfirmed)}
            />

            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="isAgreementConfirmed"
            >
              I agree, once payment is made, the workshops selected and other
              conference particulars cannot be changed.{" "}
              <span className="text-rose-500">*</span>
            </label>
          </div>
          {/* <label className="flex items-start mt-1 ">
            <div className="">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={isRegistrationConfirmed}
                required
                onChange={() =>
                  setRegistrationConfirmed(!isRegistrationConfirmed)
                }
              />
            </div>
            <div
              className={`text-[13px] font-medium ml-2 mt-[1px] cursor-pointer`}
            >
              <span>
                I confirm my registration for the above programs.{" "}
                <span className="text-rose-500">*</span>
              </span>
            </div>
          </label>

          <label className="flex items-start mt-1 ">
            <div className="">
              <input
                type="checkbox"
                required
                className="form-checkbox"
                checked={isAgreementConfirmed}
                onChange={() => setAgreementConfirmed(!isAgreementConfirmed)}
              />
            </div>
            <div
              className={`text-[13px] font-medium ml-2 mt-[1px] cursor-pointer`}
            >
              <span>
                I agree, once payment is made, the workshops selected and other
                conference particulars cannot be changed.{" "}
                <span className="text-rose-500">*</span>
              </span>
            </div>
          </label> */}
        </div>

        <div className="mt-3">
          <div className="text-black text-[16px] font-[600] mb-1">
            GST Details (optional)
          </div>
          <div className="mb-3 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="17"
              fill="gray"
              viewBox="0 -960 960 960"
              width="17"
            >
              <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
            </svg>
            <span className="text-[13px] ml-1 text-gray-500">
              THE GST DETAILS CANNOT BE UPDATED AT A LATER DATE
            </span>
          </div>

          <div className="mymd:w-[244px] mb-4">
            <label
              className="block text-[15px] font-[400] mb-0.5 text-gray-500"
              htmlFor="GSTIN"
            >
              GSTIN
            </label>
            <input
              id="GSTIN"
              className="form-input mymd:w-[500px] w-full"
              type="text"
              value={gstIn}
              placeholder="GSTIN"
              onChange={(e) => {
                setGstIn(e.target.value);
                setIsVerified("pending");
              }}
            />
            {isVerified === "pending" && (
              <p className="mt-2 text-[13px] text-danger">Invalid GSTIN</p>
            )}
          </div>

          {isVerified === "success" && (
            <>
              <div className="mymd:w-[244px] mb-4">
                <label
                  className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                  htmlFor="companyName"
                >
                  Company Name <span className="text-rose-500">*</span>
                </label>
                <input
                  id="companyName"
                  className="form-input mymd:w-[500px] w-full"
                  type="text"
                  value={gstDetails?.companyName}
                  name="companyName"
                  onChange={handleGSTDetails}
                  required
                  placeholder="Company Name"
                />
              </div>

              <div className="mymd:w-[500px] mb-4">
                <label
                  className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                  htmlFor="address"
                >
                  Address <span className="text-rose-500">*</span>
                </label>
                <textarea
                  id="address"
                  rows={3}
                  required
                  value={gstDetails?.address}
                  name="address"
                  onChange={handleGSTDetails}
                  cols={50}
                  className="whitespace-pre-line-wrap peer bg-[#ebeced] text-gray-900 text-[15px] rounded-[10px] w-full py-2 px-3"
                  placeholder="Your Address"
                ></textarea>
              </div>

              <div className="mymd:w-[500px] mb-4">
                <label
                  className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                  htmlFor="pinCode"
                >
                  Pin Code <span className="text-rose-500">*</span>
                </label>
                <input
                  id="pinCode"
                  className="form-input w-full"
                  type="text"
                  required
                  maxLength={6}
                  minLength={3}
                  value={gstDetails?.pinCode}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d+$/.test(inputValue) || inputValue === "") {
                      setGstDetails({ ...gstDetails, pinCode: inputValue });
                    }
                  }}
                  placeholder="Pin Code"
                />
              </div>

              <div className="mymd:w-[500px] mb-4">
                <label
                  className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                  htmlFor="city"
                >
                  City <span className="text-rose-500">*</span>
                </label>
                <input
                  id="city"
                  className="form-input w-full"
                  type="text"
                  required
                  value={gstDetails?.city}
                  name="city"
                  onChange={handleGSTDetails}
                  placeholder="City"
                />
              </div>

              <div className="mymd:w-[500px] mb-4">
                <label
                  className="block text-[15px] font-[400] mb-0.5 text-gray-500"
                  htmlFor="state"
                >
                  State <span className="text-rose-500">*</span>
                </label>
                <input
                  id="state"
                  value={gstDetails?.state}
                  name="state"
                  onChange={handleGSTDetails}
                  className="form-input w-full"
                  type="text"
                  required
                  placeholder="State"
                />
              </div>
            </>
          )}
        </div>

        <div className="flex gap-3 mt-4">
          <button
            type="submit"
            onClick={() => setStep(4)}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            onClick={() => {
              onFormSubmit(groupData);
            }}
            className="w-[150px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            {isSubmitting ? "Loading..." : "Make payment"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form9;
