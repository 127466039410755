import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    galaDinnerCart: [],
    registrationCart: [],
    sessionsCart: [],
    preConfrenceWorkshopCart: [],
  },
  reducers: {
    //adding product to array
    addRegistrationProduct: (state, action) => {
      return {
        ...state,
        registrationCart: [action.payload],
      };
    },

    //adding product to array
    addGalaDinner: (state, action) => {
      const checked = action.payload.checked;
      const currency = action.payload.currency || "INR";
      if (checked) {
        return {
          ...state,
          galaDinnerCart: [
            {
              name: "Gala Dinner",
              price: currency === "INR" ? 4500 : 40,
              id: 1002,
              qty: 1,
            },
          ],
        };
      } else {
        return {
          ...state,
          galaDinnerCart: [],
        };
      }
    },

    addPreConfrenceWorkshops: (state, action) => {
      let arrayOfObjects = action.payload.data;
      const totalTopicPrice = Object.values(arrayOfObjects)?.reduce(
        (total, topic) => (topic.price ? total + topic.price : total),
        0
      );

      return {
        ...state,
        preConfrenceWorkshopCart: [
          { ...action.payload.data, price: totalTopicPrice },
        ],
      };
    },

    addSessions: (state, action) => {
      let arrayOfObjects = [{ ...action.payload.data }];
      const totalTopicPrice = Object.values(arrayOfObjects[0]).reduce(
        (total, topic) => (topic.price ? total + topic.price : total),
        0
      );
      return {
        ...state,
        sessionsCart: [{ ...action.payload.data, price: totalTopicPrice }],
      };
    },

    resetCartSlice: (state, action) => {
      return {
        galaDinnerCart: [],
        registrationCart: [],
        sessionsCart: [],
        preConfrenceWorkshopCart: [],
      };
    },
  },
});

export const {
  addRegistrationProduct,
  addGalaDinner,
  addPreConfrenceWorkshops,
  addSessions,
  resetCartSlice,
} = cartSlice.actions;
export default cartSlice.reducer;
