import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentStatusMessage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [countdown, setCountdown] = useState(10);
  const paymentStatus = useSelector(
    (state) => state.paymentSlice.paymentStatus
  );

  let receiptUrl = searchParams.get("url");
  if (!receiptUrl) {
    receiptUrl = localStorage.getItem("receiptUrl");
  }

  useEffect(() => {
    if (paymentStatus === "failed") {
      const countdownInterval = setInterval(() => {
        if (countdown === 1) {
          clearInterval(countdownInterval);
          navigate("/");
        } else {
          setCountdown(countdown - 1);
        }
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else if (paymentStatus === "") {
      navigate("/");
    }
  }, [countdown, navigate, paymentStatus]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="">
        <div
          className="mymd:h-[350px] mymd:w-[600px] w-full flex flex-col items-center mymd:border justify-center px-6 rounded-sm mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]"
          // style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
        >
          {paymentStatus === "success" ? (
            <>
              <svg
                width="90"
                height="90"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_118_2684)">
                  <path
                    d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM20 34L10 24L12.82 21.18L20 28.34L35.18 13.16L38 16L20 34Z"
                    fill="#2ECC71"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_118_2684">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="text-[26px] mt-[10px] font-[500] text-center">
                Payment successful
              </span>

              <p className="mt-[10px] font-[500] text-center">
                Thank you! Your registrations was successful, a confirmation
                mail has been sent to your email.
              </p>
              <div
                onClick={() => {
                  window.open(receiptUrl, "_blank");
                }}
                type="button"
                className="w-[70%] mymd:w-[250px] flex mymd:h-[40px] h-[45px] justify-center items-center bg-primary rounded-[10px] gap-1 cursor-pointer text-white mt-4"
              >
                Download Receipt
              </div>
            </>
          ) : paymentStatus === "failed" ? (
            <>
              <svg
                width="90"
                height="90"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 0C7.8225 0 0 7.8225 0 17.5C0 27.1775 7.8225 35 17.5 35C27.1775 35 35 27.1775 35 17.5C35 7.8225 27.1775 0 17.5 0ZM26.25 23.7825L23.7825 26.25L17.5 19.9675L11.2175 26.25L8.75 23.7825L15.0325 17.5L8.75 11.2175L11.2175 8.75L17.5 15.0325L23.7825 8.75L26.25 11.2175L19.9675 17.5L26.25 23.7825Z"
                  fill="#E74C3C"
                />
              </svg>

              <span className="text-[26px] mt-[10px] font-[500] text-center">
                Failed to Register
              </span>

              <p className="mt-[10px] font-[500] text-center">
                Payment failed. Please try again later.
              </p>
              <p className="mt-[10px] font-[500] text-center">
                Redirecting in{" "}
                <span className="font-[600] text-primary">
                  {countdown} seconds
                </span>
                ...
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusMessage;
