import React from "react";
import { groupSteps } from "../../partials/Steps";

const GroupFormSteps = ({ step, setStep }) => {
  let subSteps = [5, 6, 7, 8];

  return (
    <div className="flex justify-center ">
      {groupSteps?.map((data, index) => {
        return (
          <React.Fragment key={index}>
            {subSteps.includes(data?.num) ? (
              step >= 5 &&
              step <= 8 && (
                <div className="mt-[100px] w-[100px]">
                  <div className="flex justify-center items-center relative ">
                    <span
                      className={`absolute top-[-25px] ${
                        data?.num === step
                          ? "text-[16px] font-[600]"
                          : "text-[15px] font-normal"
                      } mr-0`}
                    >
                      {data?.name}
                    </span>
                    {data?.num <= step ? (
                      <div
                        className={`${
                          data?.num === step ? "bg-primary" : "bg-primary"
                        }  cursor-pointer rounded-full  flex justify-center items-center w-[20px] h-[20px] transition-colors duration-300 `}
                        onClick={() => setStep(data?.num)}
                      >
                        <div className="bg-white rounded-full w-[4px] h-[4px]"></div>
                      </div>
                    ) : (
                      <div
                        className={`bg-white border-2 rounded-full w-[20px] h-[20px] flex justify-center items-center transition-colors duration-300 mr-0`}
                      >
                        <div className="bg-white rounded-full w-[4px] h-[4px]"></div>
                      </div>
                    )}
                    <div className={`${data?.num === 8 ? "mb-0" : "mb-0"}`}>
                      {groupSteps?.length === data?.num ? (
                        <></>
                      ) : (
                        <>
                          {data?.num <= step ? (
                            <div className="absolute border border-primary h-0 w-full transition-all duration-300"></div>
                          ) : (
                            <div className="absolute border h-0 w-full transition-all duration-300"></div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className=" mt-[90px]  w-[150px]">
                <div className=" flex justify-center items-center relative ">
                  {/* <div className="flex items-center h-full w-[100px] relative"> */}
                  <span
                    className={`absolute top-[-25px] ${
                      data?.num === step
                        ? "text-[16px] font-[600]"
                        : "text-[15px] font-normal"
                    } mr-0`}
                  >
                    {data?.name}
                  </span>

                  {data?.num <= step ? (
                    <div
                      className={`${
                        data?.num === step ? "bg-primary" : "bg-primary"
                      }  cursor-pointer rounded-full  flex justify-center items-center w-[40px] h-[40px] transition-colors duration-300`}
                      onClick={() => setStep(data?.num)}
                    >
                      <div className="">{data?.img}</div>
                    </div>
                  ) : (
                    <div
                      className={`bg-white border-2 rounded-full w-[40px] h-[40px] flex justify-center items-center transition-colors duration-300`}
                    >
                      <div className="">{data?.img}</div>
                    </div>
                  )}

                  <div className="">
                    {groupSteps?.length === data?.num ? (
                      <></>
                    ) : (
                      <>
                        {data?.num <= step ? (
                          <div className="absolute border border-primary h-0 w-full transition-all duration-300"></div>
                        ) : (
                          <div className="absolute border h-0 w-full transition-all duration-300"></div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default GroupFormSteps;
