import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userData",
  initialState: {
    formData: {},
    groupFormData: {
      groupAttendees: [],
    },
    isEdit: false,
    registerLeader: false,
    totalCartValue: 0,
    groupTotal: 0,
  },
  reducers: {
    //adding product to compare array
    addUserData: (state, action) => {
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
      };
    },

    //reseting array to initial state
    calculateCartValue: (state, action) => {
      let cartData = action.payload.data;
      const galaDinnerCart = cartData?.galaDinnerCart;
      const registrationCart = cartData.registrationCart;
      const sessionsCart = cartData.sessionsCart;
      const preConfrenceWorkshopCart = cartData.preConfrenceWorkshopCart;

      const totalValue = [
        ...galaDinnerCart,
        ...registrationCart,
        ...sessionsCart,
        ...preConfrenceWorkshopCart,
      ]
        .map((item) => item.price || 0)
        .reduce((total, price) => total + price, 0);

      return {
        ...state,
        totalCartValue: totalValue,
      };
    },

    addGroupData: (state, action) => {
      return {
        ...state,
        groupFormData: { ...state.groupFormData, ...action.payload },
      };
    },

    addAttendeeGroupData: (state, action) => {
      const data = {
        ...state,
        groupFormData: {
          ...state.groupFormData,
          groupAttendees: [
            ...state.groupFormData.groupAttendees,
            action.payload,
          ],
        },
      };
      return data;
    },

    deleteAttendee: (state, action) => {
      const emailToDelete = action.payload;
      const updatedGroupAttendees = state.groupFormData.groupAttendees.filter(
        (attendee) => attendee.email !== emailToDelete
      );

      state.groupFormData.groupAttendees = updatedGroupAttendees;
    },

    setIsEdit: (state, action) => {
      return {
        ...state,
        isEdit: action.payload.value,
      };
    },

    setRegisterLeader: (state, action) => {
      return {
        ...state,
        registerLeader: action.payload.value,
      };
    },

    editFormData: (state, action) => {
      const editedAttendee = action.payload;
      const updatedGroupAttendees = state.groupFormData.groupAttendees.map(
        (attendee) =>
          attendee.email === editedAttendee.email ? editedAttendee : attendee
      );

      state.groupFormData.groupAttendees = updatedGroupAttendees;
    },
    calcualteGroupTotal: (state, action) => {
      const total = action?.payload?.reduce(
        (acc, cartItem) => acc + cartItem.totalCartValue,
        0
      );
      return {
        ...state,
        groupTotal: total,
      };
    },
    resetCartTotal: (state, action) => {
      return {
        ...state,
        totalCartValue: 0,
      };
    },

    resetFormData: (state, action) => {
      return {
        ...state,
        formData: {},
      };
    },
  },
});

export const {
  addUserData,
  calculateCartValue,
  addGroupData,
  addAttendeeGroupData,
  deleteAttendee,
  setIsEdit,
  editFormData,
  calcualteGroupTotal,
  resetCartTotal,
  setRegisterLeader,
  resetFormData,
} = userSlice.actions;
export default userSlice.reducer;
