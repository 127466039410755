import { createSlice } from "@reduxjs/toolkit";

export const groupCartSlice = createSlice({
  name: "groupCart",
  initialState: {
    groupCart: [],
  },
  reducers: {
    //adding product to array
    addToGroupCart: (state, action) => {
      return {
        ...state,
        groupCart: [...state.groupCart, action.payload],
      };
    },
    editGroupCart: (state, action) => {
      const editedAttendee = action.payload;
      const updatedGroupCart = state.groupCart.map((attendee) =>
        attendee.email === editedAttendee.email ? editedAttendee : attendee
      );

      state.groupCart = updatedGroupCart;
    },
    deleteSingleUserCartData: (state, action) => {
      const attendeeToDelete = action.payload;
      const updatedGroupCart = state.groupCart.filter(
        (attendee) => attendee.email !== attendeeToDelete
      );
      state.groupCart = updatedGroupCart;
    },
    resetGroupCartSlice: (state, action) => {
      return {
        groupCart: [],
      };
    },
  },
});

export const {
  addToGroupCart,
  editGroupCart,
  resetGroupCartSlice,
  deleteSingleUserCartData,
} = groupCartSlice.actions;
export default groupCartSlice.reducer;
