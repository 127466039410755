import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addGroupData } from "../../redux/stores/reducers/userSlice";
import Select from "../../common/Select";
import { Occupations } from "../../partials/Occupations";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";
import countryCodes from "../../partials/CountryCodes.json";
import CountryCode from "../../common/CountryCode";

const Form1 = ({ setTriggerPopup, triggerPopup, setStep }) => {
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("+91");
  const [countryCode2, setCountryCode2] = useState("+91");
  const [allCountryCode, setAllCountryCode] = useState(countryCodes);
  const [value1, setValue1] = useState([]);
  const [emailValidation, setEmailValidation] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("groupForm1"));
    if (formData) {
      reset({ ...formData });
      setValue1(formData?.occupationDropDown);
      setCountryCode(formData?.countryCode);
      setCountryCode2(formData?.countryCode2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    if (!value1?.label) {
      dispatch(
        setShowToast({
          message: "Please complete all fields",
          category: "danger",
        })
      );
      return;
    }

    const matchingCountryCode = countryCodes.find(
      (country) => country.dial_code === countryCode
    );

    const matchingCountryCode2 = countryCodes.find(
      (country) => country.dial_code === countryCode2
    );
    const regex = /^[\d]{10}$/;
    if (!matchingCountryCode || !matchingCountryCode2) {
      dispatch(
        setShowToast({
          message: "Invalid Country Code.",
          category: "danger",
        })
      );

      return;
    }

    if (!regex.test(data.mobile)) {
      dispatch(
        setShowToast({
          message: "Invalid Mobile Number",
          category: "danger",
        })
      );
      return;
    }

    if (!regex.test(data.contactNumber)) {
      dispatch(
        setShowToast({
          message: "Invalid Mobile Number",
          category: "danger",
        })
      );
      return;
    }

    const updatedData =
      value1?.label !== "Other"
        ? {
            ...data,
            firstName: data?.firstName?.trim(),
            lastName: data?.lastName?.trim(),
            companyName: data?.companyName?.trim()?.replace(/\s*,\s*/g, " - "),
            occupation: value1?.label,
            contactNumber: countryCode2 + " " + data.contactNumber,
            countryCode: countryCode,
            email: data?.email?.toLowerCase().trim(),
          }
        : {
            ...data,
            firstName: data?.firstName?.trim(),
            lastName: data?.lastName?.trim(),
            companyName: data?.companyName?.trim()?.replace(/\s*,\s*/g, " - "),
            occupation: data?.occupation?.trim(),
            contactNumber: countryCode2 + " " + data.contactNumber,
            countryCode: countryCode,
            email: data?.email?.toLowerCase().trim(),
          };
    dispatch(addGroupData(updatedData));
    const localData = {
      ...data,
      occupationDropDown: value1,
      countryCode: countryCode,
      countryCode2: countryCode2,
    };
    localStorage.setItem("groupForm1", JSON.stringify({ ...localData }));
    setStep(2);
  };

  const isYahooEmail = (email) => {
    return !/@yahoo\./i.test(email);
  };

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="mymd:flex justify-between ">
          <div className="mymd:w-[244px] mb-4">
            <label
              className="block text-[15px] font-[400] mb-1 text-gray-500"
              htmlFor="firstName"
            >
              First Name <span className="text-rose-500">*</span>
            </label>
            <input
              id="firstName"
              className="form-input w-[100%]"
              type="text"
              required
              placeholder="First Name"
              {...register("firstName", {
                required: "First Name is required",
                validate: (value) => {
                  const trimmedValue = value.trim();
                  if (trimmedValue === "") {
                    return "Please enter a valid first name";
                  }
                  return true;
                },
              })}
            />
          </div>

          <div className="mymd:w-[243px] mb-4">
            <label
              className="block text-[15px] font-[400] mb-1 text-gray-500"
              htmlFor="lastName"
            >
              Last Name <span className="text-rose-500">*</span>
            </label>
            <input
              id="lastName"
              className="form-input w-full"
              type="text"
              required
              placeholder="Last Name"
              {...register("lastName", {
                required: "Last Name is required",
                validate: (value) => {
                  const trimmedValue = value.trim();
                  if (trimmedValue === "") {
                    return "Please enter a valid last name";
                  }
                  return true;
                },
              })}
            />
          </div>
        </div>

        <div>
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="mobile"
          >
            WhatsApp Number <span className="text-rose-500">*</span>
          </label>
          <div className="flex gap-2 items-center mb-4">
            <CountryCode
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              allCountryCode={allCountryCode}
              setAllCountryCode={setAllCountryCode}
            />

            <div className="w-full">
              <input
                id="mobile"
                className="form-input w-full"
                type="tel"
                required
                maxLength={10}
                placeholder="WhatsApp Number"
                inputMode="numeric"
                {...register("mobile")}
              />
            </div>
          </div>
        </div>

        <div>
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="mobile"
          >
            Contact Number <span className="text-rose-500">*</span>
          </label>
          <div className="flex gap-1 items-center mb-4">
            <CountryCode
              countryCode={countryCode2}
              setCountryCode={setCountryCode2}
              allCountryCode={allCountryCode}
              setAllCountryCode={setAllCountryCode}
            />
            <div className="w-full">
              <input
                id="contactNumber"
                className="form-input w-full"
                type="tel"
                required
                maxLength={10}
                placeholder="Contact Number"
                inputMode="numeric"
                {...register("contactNumber")}
              />
            </div>
          </div>
        </div>

        <div className="mymd:w-[500px] mb-6">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="email"
          >
            Email <span className="text-rose-500">*</span> <br />
          </label>

          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                validEmail: (value) =>
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    value
                  ) || "Invalid email format",
                notYahooEmail: (value) =>
                  isYahooEmail(value) ||
                  "Sorry, we do not support Yahoo emails, for assistance contact: +91 8591076985",
              },
            }}
            render={({ field }) => (
              <div>
                <input
                  type="text"
                  {...field}
                  className="form-input w-full"
                  placeholder="Email"
                />
                <p className="text-danger text-[13px] font-medium mt-2">
                  {errors?.email && errors?.email?.message}
                </p>
              </div>
            )}
          />
        </div>

        {/* <div className="mymd:w-[500px] mb-4">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="email"
          >
            Email <span className="text-rose-500">*</span>
          </label>
          <input
            id="email"
            className="form-input w-full"
            type="email"
            required
            placeholder="Email"
            {...register("email")}
          />
          {emailValidation && (
            <p className="text-danger text-[13px] font-medium mt-2">
              Sorry, we do not support Yahoo email addresses.
            </p>
          )}
        </div> */}

        <div className="mymd:w-[500px] mb-4">
          <label
            className="block text-[15px] font-[400] mb-1 text-gray-500"
            htmlFor="companyName"
          >
            Organization <span className="text-rose-500">*</span>
          </label>
          <input
            id="companyName"
            className="form-input w-full"
            type="text"
            required
            placeholder="Company Name"
            {...register("companyName", {
              required: "This field is required",
              validate: (value) => {
                const trimmedValue = value.trim();
                if (trimmedValue === "") {
                  return "Please enter a valid text";
                }
                return true;
              },
            })}
          />
        </div>

        <div className=" text-[15px] font-[400] text-gray-500 mb-4 ">
          <label
            htmlFor="occupation"
            className="block text-[15px] font-[400] mb-1 text-gray-500"
          >
            Occupation <span className="text-rose-500">*</span>
          </label>
          <Select
            // register={register}
            id="occupation"
            options={Occupations}
            value={value1}
            required={true}
            onChange={(data) => {
              // setValue("occupation", data);
              setValue1(data);
            }}
          />
        </div>

        {value1?.label === "Other" && (
          <div className="mymd:w-[500px] mb-4">
            <input
              id="occupation"
              className="w-full border-b border-gray-300 focus:border-blue-500 outline-none bg-white py-1 px-2 "
              type="text"
              required
              placeholder="Occupation"
              {...register("occupation", {
                required: "This field is required",
                validate: (value) => {
                  const trimmedValue = value.trim();
                  if (trimmedValue === "") {
                    return "Please enter a valid text";
                  }
                  return true;
                },
              })}
            />
          </div>
        )}

        <div className="font-[500]">
          Note:
          <p>- GST included for all prices</p>
          <p className="my-1">
            - Pre-conference topics and timings are subjected to change.
          </p>
          <p>
            - For any assistance please contact:{" "}
            <span className="text-primary">+91 85910 76985</span>
          </p>
        </div>

        <button
          type="submit"
          className="w-full flex h-[40px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px] mt-3"
        >
          Next
        </button>
      </form>
    </div>
  );
};

export default Form1;
