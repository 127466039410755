import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addUserData } from "../../redux/stores/reducers/userSlice";
import { setShowToast } from "../../redux/stores/reducers/toastSlice";

const Form2 = ({ setStep }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm({ mode: "onChange" });

  const onSubmit = (data) => {
    if (data.privacyPolicyConsent !== "Yes") {
      dispatch(
        setShowToast({
          message: "Please accecpt data protection and privacy policy",
          category: "danger",
        })
      );
      return;
    }

    dispatch(
      addUserData({
        ...data,
        isDelegateList: data?.isDelegateList === false ? "No" : "Yes",
        personalDataConsent: data?.personalDataConsent === false ? "No" : "Yes",
      })
    );
    localStorage.setItem("form2", JSON.stringify({ ...data }));
    setStep(3);
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("form2"));
    if (formData) {
      reset({ ...formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mymd:w-[600px] mymd:flex bg-white w-[100vw] z-30 justify-center relative mymd:my-[30px] py-6 rounded-[10px] mymd:px-0 px-4 mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)]">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="text-black text-[17px] font-[600] mb-0 pr-[0px] mymd:w-[500px]">
          Data Protection Consent:
          <div className="flex mt-3 items-start mymd:gap-1.5 gap-1.5">
            <input
              type="checkbox"
              className="form-checkbox mt-1 min-w-[17px]"
              required
              value="Yes"
              id="privacyPolicyConsent"
              {...register("privacyPolicyConsent")}
            />
            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="privacyPolicyConsent"
            >
              I understand and hereby accept that my personal data will be kept
              confidential as per data protection and privacy policy.{" "}
              <span className="text-rose-500">*</span>
            </label>
          </div>
          <div className="flex mt-3 items-start mymd:gap-1.5 gap-1.5">
            <input
              type="checkbox"
              className="form-checkbox mt-1 min-w-[17px]"
              id="personalDataConsent"
              value="Yes"
              {...register("personalDataConsent")}
            />

            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="personalDataConsent"
            >
              I consent for my data to be shared with exhibitors and sponsors
              when attending a sponsored symposium or connecting on a booth.
              Sponsors and exhibitors will then be able to contact me for
              commercial or marketing purposes.
            </label>
          </div>
          {/* <label className="flex mt-5 items-start text-[13px] font-[600] cursor-pointer mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              className="form-checkbox mt-1"
              id="personalDataConsent"
              value="Yes"
              {...register("personalDataConsent")}
            />
            <div>
              I consent for my data to be shared with exhibitors and sponsors
              when attending a sponsored symposium or connecting on a booth.
              Sponsors and exhibitors will then be able to contact me for
              commercial or marketing purposes.
            </div>
          </label> */}
          <div className="flex mt-3 items-start mymd:gap-1.5 gap-1.5">
            <input
              type="checkbox"
              className="form-checkbox mt-1 min-w-[17px]"
              id="isDelegateList"
              value="Yes"
              {...register("isDelegateList")}
            />

            <label
              className="text-[13px] font-[600] cursor-pointer "
              htmlFor="isDelegateList"
            >
              I want to be listed on the delegate list of ESPEN 2023.
            </label>
          </div>
          {/* <label className="flex mt-5 items-center text-[13px] font-[600] cursor-pointer mymd:gap-2 gap-1.5">
            <input
              type="checkbox"
              className="form-checkbox "
              id="isDelegateList"
              value="Yes"
              {...register("isDelegateList")}
            />
            I want to be listed on the delegate list of ESPEN 2023.
          </label> */}
          {/* <div className="text-sm font-medium">
            <p className="mt-4">
              I understand and hereby accept that my personal data will be
              shared with exhibitors and sponsors when connecting on a booth or
              attending a sponsored symposium. Sponsors and exhibitors will then
              be able to contact me for commercial or marketing purposes.
            </p>
            <div className="flex items-center gap-3 mt-2">
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  id="personalDataConsent"
                  className="mr-1"
                  value={"Yes"}
                  {...register("personalDataConsent")}
                />
                Yes
              </label>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  id="personalDataConsent"
                  className="mr-1"
                  value={"No"}
                  {...register("personalDataConsent")}
                />
                No
              </label>
            </div>
          </div> */}
          {/* <div className="mt-4">
            <p className="text-sm font-medium">
              Click{" "}
              <span
                className="cursor-pointer underline text-primary"
                onClick={() =>
                  window.open(
                    `https://espencongress.com/confirmed-sponsors/`,
                    "_blank"
                  )
                }
              >
                here
              </span>{" "}
              for the list of sponsors & exhibitors (please note, that this list
              can be updated until the actual congress dates. Make sure you
              consult it regularly). You may contact{" "}
              <span className="cursor-pointer underline text-primary">
                espen@mci-group.com
              </span>{" "}
              if you do not wish to share your contacts with a specific sponsor.
            </p>

            <p className="text-sm font-medium mt-4">
              <span className="font-[600]">Physical events only: </span>
              Sponsors and exhibitors may scan or pair your badges when you
              attend their satellite symposium or visit their booth during an
              event. If you do not want your badge to be scanned or paired, it
              is your responsibility to let the sponsor or exhibitor know of
              that decision. Allowing a sponsor or exhibitor to scan or pair
              your badge is considered a consent to share your personal data
              with them and as a consequence overrides the decision you have
              taken on “Data sharing” during the registration process. It is
              also important to know that when there is no badge scanning or
              pairing on an exhibitor booth and you still consent to sharing
              your business card with an exhibitor, that exhibitor will be able
              to contact you using your/their personal data retrieved through
              your/their business card.
            </p>
          </div> */}
          <div className="text-black text-[17px] font-[600] mt-3 pr-[50px]">
            {/* Individual Data Privacy */}
            {/* <p className="text-sm font-medium mt-2">
              For further information on how your data will be used, we invite
              you to carefully read the{" "}
              <span
                className="cursor-pointer underline text-primary"
                onClick={() =>
                  window.open(
                    `https://www.wearemci.com/en/privacy-policy`,
                    "_blank"
                  )
                }
              >
                Data Protection and Privacy Policy.
              </span>{" "}
              (MCI acting as data processor on this event).
            </p> */}
          </div>
        </div>
        <div className="font-[500]">
          Note:
          <p>- GST included for all prices</p>
          <p className="my-1">
            - Pre-conference topics and timings are subjected to change.
          </p>
          <p>
            - For any assistance please contact:{" "}
            <span className="text-primary">+91 85910 76985</span>
          </p>
        </div>
        <div className="flex gap-3 mt-4 ">
          <button
            type="button"
            onClick={() => setStep(1)}
            className="w-[110px] flex h-[38px] justify-center items-center bg-white py-0 rounded-[10px] text-primary font-[500] text-[17px] border border-primary"
          >
            Previous
          </button>
          <button
            type="submit"
            className="w-[110px] flex h-[38px] justify-center items-center bg-primary py-0 rounded-[10px]  text-white font-[500] text-[17px]"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form2;
